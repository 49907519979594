<!--流程-->
<template>
    <div class="processFlow">
        <Header @handleClick="handleClick" :currentTab="currentTab" />
        <div class="box-content">
           
            <div class="fd-nav-content">
                <section class="dingflow-design">
                    <div class="box-scale" id="box-scale" :style="'transform: scale(' +
                        nowVal / 100 +
                        '); transform-origin: 50% 0px 0px;'
                        ">
                        <nodeWrap :nodeConfig="nodeConfig" @updateNodeConfig="nodeConfig = $event"
                            :flowPermission="flowPermission" @update:flowPermission="flowPermission = $event"
                            :isTried="isTried" :directorMaxLevel="directorMaxLevel" :tableId="tableId"
                            :conditionsList="conditionsList">
                        </nodeWrap>
                        <div class="end-node">
                            <div class="end-node-circle"></div>
                            <div class="end-node-text">流程结束</div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <el-dialog title="提示" :model-value="tipVisible">
            <div class="ant-confirm-body">
                <i class="anticon anticon-close-circle" style="color: #f00"></i>
                <span class="ant-confirm-title">当前无法发布</span>
                <div class="ant-confirm-content">
                    <div>
                        <p class="error-modal-desc">以下内容不完善，需进行修改</p>
                        <div class="error-modal-list">
                            <div class="error-modal-item" v-for="(item, index) in tipList" :key="index">
                                <div class="error-modal-item-label">流程设计</div>
                                <div class="error-modal-item-content">
                                    {{ item.name }} 未选择{{ item.type }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="dialog-return-button" @click="tipVisible = false">我知道了</el-button>
                    <el-button style="color: #fff" class="dialog-foot-button" color="#4381e6"
                        @click="tipVisible = false">前往修改</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import Header from './header.vue';
import nodeWrap from "@/components/nodeWrap.vue";

import {
    goAddProcess,
    queryProcessDetail,
    getConditionsList,
} from "@/api/examine/process.js";
export default {
    components: { nodeWrap, Header },
    data() {
        return {
            defaultData: {
                "tableid": 1,
                "workFlowVersionId": "",
                "workFlowDef": {
                    "name": "审批",
                    "publicFlag": 1,
                    "sortNo": 5,
                    "duplicateRemovelFlag": 1,
                    "optionTip": "",
                    "optionNotNull": 0,
                    "status": 1
                },
                "directormaxlevel": 4,
                "flowpermission": [],
                "nodeconfig": {
                    "pkId": "sid-start-node",
                    "nodeName": "发起人",
                    "type": 0,
                    "priorityLevel": "",
                    "settype": "",
                    "selectMode": "",
                    "selectRange": "",
                    "examineRoleId": "",
                    "directorLevel": "",
                    "replaceByUp": "",
                    "examineMode": "",
                    "noHanderAction": "",
                    "examineEndType": "",
                    "examineEndRoleId": "",
                    "examineEndDirectorLevel": "",
                    "ccSelfSelectFlag": "",
                    "conditionList": [],
                    "nodeUserList": [],
                    "childNode": null,
                    "conditionNodes": []
                }
            },
            formData: {},
            currentTab: 3,
            isTried: false,
            tipList: [],
            tipVisible: false,
            nowVal: 100,
            processConfig: {
                tableId: "",
                workFlowVersionId: "",
                directorMaxLevel: 1,
                flowPermission: [],
                nodeConfig: {
                    pkId: "sid-start-node",
                    nodeName: "发起人",
                    type: 0,
                    priorityLevel: "",
                    settype: "",
                    selectMode: "",
                    selectRange: "",
                    examineRoleId: "",
                    directorLevel: "",
                    replaceByUp: "",
                    examineMode: "",
                    noHanderAction: "",
                    examineEndType: "",
                    examineEndRoleId: "",
                    examineEndDirectorLevel: "",
                    ccSelfSelectFlag: "",
                    conditionList: [],
                    nodeUserList: [],
                    childNode: null,
                    conditionNodes: [],
                },
            },
            nodeConfig: {
                nodeName: "发起人",
                type: 0,
                priorityLevel: "",
                settype: "",
                selectMode: "",
                selectRange: "",
                examineRoleId: "",
                directorLevel: "",
                replaceByUp: "",
                examineMode: "",
                noHanderAction: "",
                examineEndType: "",
                examineEndRoleId: "",
                examineEndDirectorLevel: "",
                ccSelfSelectFlag: "",
                conditionList: [],
                nodeUserList: [],
                childNode: null,
                conditionNodes: [],
            },
            flowPermission: [],
            directorMaxLevel: 2,
            dialogVisible: true,
            tableId: "",
            conditionsList: [],
        };
    },
    created() {

    },

    mounted() {
        this.formData.processId = this.$route.query.processId;
        this.getConditionsList();
        this.getProcessDetail();

    },
    methods: {
        handleClick(index) {
            const pathArr = ['/examine/base', '/examine/condition', '/examine/design'];
            switch (index) {
                case 3:

                    console.log("发布流程");
                    break;
                case 4:
                    this.$router.go(-1);
                    break;
                case 5:
                    // 发布流程
                    this.goRelease();
                    break;
                default:

                    break;
            }
        },
        // 查询流程条件
        getConditionsList() {
            let params = {
                processId: this.formData.processId,
            };

            getConditionsList(params).then((res) => {
                res.data.forEach((item) => {
                    let obj = {
                        columnId: item.variablesName,
                        showType:item.inputType==5?"3":'1',
                        showName: item.formItemName,
                        columnType: item.inputType==5?"four":'Double',
                        fixedDownBoxValue:item.inputType==5?item.defaultVal.split(' '):'',
                        inputType:item.inputType,
                        formItemId:item.formItemId,
                        optType: item.inputType==5?"4":'1',
                        type: item.inputType==5?21:2,
                        zdy2: item.inputType==5?item.variablesName:''
                    };
                    this.conditionsList.push(obj);
                });
            });
        },
        // 新建流程
        goAddProcess(data) {
            let params = {
                processName: this.formData.processName,
                processType: this.formData.processType,
                processGroup: this.formData.processGroup,
                iconUrl: this.formData.attId,
                remark: this.formData.remark,
                processContent: "",
                stop: "",
                prefixUrl: this.formData.prefixUrl,
                url: this.formData.url,
                nodeDtoList: this.nodeConfig,
            };
            goAddProcess(data)
                .then(() => {
                    this.$message.success("流程发布成功");
                    this.$router.push({ path: '/examine/setting' });

                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        getRange(a) {
            if (a == '1') {
                return '＜'
            } else if (a == '2') {
                return '＞'
            } else if (a == '3') {
                return '≤'
            } else if (a == '4') {
                return '＝'
            } else if (a == '5') {
                return '≥'
            }
        },
        // 发布流程
        goRelease() {
            this.isTried = true;
            this.tipList = [];
            this.reErr(this.nodeConfig);
            if (this.tipList.length != 0) {
                this.tipVisible = true;
                return;
            }
            this.processConfig.flowPermission = this.flowPermission;
            if (this.nodeConfig.childNode == null) {
                this.$message.error("请先设置流程");
                return
            }
            let processContent = JSON.stringify(this.nodeConfig)
            let data = {
                processId: this.formData.processId,
                processContent: processContent,
                nodeDtoList: [{
                    nodeName: '开始',
                    nodeType: 1,
                }]
            }
            // 数据处理
            var a = [];
            var obj = this.nodeConfig.childNode;

            let that = this
            function deep(obj, arr2) {
                if (obj == undefined || obj == null) {
                    obj == null
                } else {
                    // 审核人
                    if (obj.type == 1) {
                        let assigneeType, assigneeLeaderLevel, assigneeRoleId, assigneeMethod, assigneeSelectType, assigneeBusinessRole,assigneeBusinessRoleId,assigneeBusinessRoleObj;
                        let assigneeSelectRange; //自选范围 1全公司 2指定角色
                        let assigneeEmpIds = []; // 指定成员id
                        let assigneeSelectRole; // 自选id
                        let nodeActionVo = {
                            eventType: obj.eventType,
                            eventName: obj.eventName,
                            eventAction: obj.eventAction,
                            rejectEnable: obj.rejectEnable,
                            stageName: obj.stageName,
                        };
                        if (obj.settype == 5) { // 发起人自己
                            assigneeType = 1
                        } else if (obj.settype == 6) {
                            assigneeType = 6;
                            assigneeSelectType = obj.assigneeSelectType;
                            assigneeBusinessRoleId = obj.assigneeBusinessRoleObj.assigneeBusinessRoleId;
                            assigneeBusinessRole=obj.assigneeBusinessRoleObj.assigneeBusinessRole;
                        } else if (obj.settype == 2) { // 主管
                            assigneeType = 4;
                            assigneeLeaderLevel = obj.directorLevel;
                            assigneeMethod = obj.examineMode;
                            assigneeSelectRange = ''
                        } else if (obj.settype == 4) { // 发起人自选
                            assigneeType = 5;
                            if (obj.selectRange == 1) {
                                assigneeSelectRole = '';
                                assigneeSelectRange = obj.selectRange;
                            } else {
                                assigneeSelectRole = obj.nodeUserList[0].targetId;
                                assigneeSelectRange = 2
                            }

                        } else if (obj.settype == 1) { //指定成员
                            // assigneeMethod
                            assigneeType = 2;
                            assigneeSelectRange = ''
                            obj.nodeUserList.forEach((v, k) => {
                                assigneeEmpIds.push(v.targetId)
                            })
                            if (obj.nodeUserList.length == 1) {
                                assigneeMethod = ''
                            } else {
                                assigneeMethod = obj.examineMode
                            }
                        } else if (obj.settype == 8) { // 角色
                            assigneeRoleId = obj.nodeUserList[0].targetId;
                            assigneeMethod = obj.examineMode;
                            assigneeType = 3;
                            assigneeSelectRange = ''
                        }
                        obj = {
                            childNode: obj.childNode,
                            nodeName: obj.nodeName,
                            nodeType: 2,
                            assigneeType: assigneeType, // 1.发起人自己、2.指定成员、3.角色、4.主管、5.发起人自选
                            assigneeEmpIds: assigneeEmpIds.toString(),
                            assigneeLeaderLevel: assigneeLeaderLevel ? assigneeLeaderLevel : '',
                            assigneeRoleId: assigneeRoleId ? assigneeRoleId : '',
                            assigneeMethod: assigneeMethod, // 1会签，2或签
                            assigneeSelectRange: assigneeSelectRange,
                            assigneeSelectRole: assigneeSelectRole, //自选id
                            assigneeSelectType: assigneeSelectType, //自选id
                            assigneeBusinessRole: assigneeBusinessRole, //自选id
                            assigneeBusinessRoleId:assigneeBusinessRoleId, //自选id
                            nodeActionVo: nodeActionVo //办理事项
                        }
                        arr2.push(obj)
                        deep(obj.childNode, arr2)
                    }
                    //新家的办理人
                    else if (obj.type == 10) {
                        let assigneeType, assigneeLeaderLevel, assigneeRoleId, assigneeMethod, assigneeSelectType, assigneeBusinessRole;
                        let assigneeSelectRange; //自选范围 1全公司 2指定角色
                        let assigneeEmpIds = []; // 指定成员id
                        let assigneeSelectRole; // 自选id
                        let nodeActionVo = {
                            eventType: obj.eventType,
                            eventName: obj.eventName,
                            eventAction: obj.eventAction,
                            rejectEnable: obj.rejectEnable,
                        };
                        if (obj.settype == 5) { // 发起人自己
                            assigneeType = 1
                        } else if (obj.settype == 2) { // 主管
                            assigneeType = 4;
                            assigneeLeaderLevel = obj.directorLevel;
                            assigneeMethod = obj.examineMode;
                            assigneeSelectRange = ''
                        } else if (obj.settype == 4) { // 发起人自选
                            assigneeType = 5;
                            if (obj.selectRange == 1) {
                                assigneeSelectRole = '';
                                assigneeSelectRange = obj.selectRange;
                            } else {
                                assigneeSelectRole = obj.nodeUserList[0].targetId;
                                assigneeSelectRange = 2
                            }

                        } else if (obj.settype == 1) { //指定成员
                            // assigneeMethod
                            assigneeType = 2;
                            assigneeSelectRange = ''
                            obj.nodeUserList.forEach((v, k) => {
                                assigneeEmpIds.push(v.targetId)
                            })
                            if (obj.nodeUserList.length == 1) {
                                assigneeMethod = ''
                            } else {
                                assigneeMethod = obj.examineMode
                            }
                        } else if (obj.settype == 8) { // 角色
                            assigneeRoleId = obj.nodeUserList[0].targetId;
                            assigneeMethod = obj.examineMode;
                            assigneeType = 3;
                            assigneeSelectRange = ''
                        } else if (obj.settype == 6) {
                            assigneeType = 6;
                            assigneeSelectType = obj.assigneeSelectType;
                            assigneeBusinessRole = obj.assigneeBusinessRole;
                        }
                        obj = {
                            childNode: obj.childNode,
                            nodeName: obj.nodeName,
                            nodeType: 3,
                            assigneeType: assigneeType, // 1.发起人自己、2.指定成员、3.角色、4.主管、5.发起人自选
                            assigneeEmpIds: assigneeEmpIds.toString(),
                            assigneeLeaderLevel: assigneeLeaderLevel ? assigneeLeaderLevel : '',
                            assigneeRoleId: assigneeRoleId ? assigneeRoleId : '',
                            assigneeMethod: assigneeMethod, // 1会签，2或签
                            assigneeSelectRange: assigneeSelectRange,
                            assigneeSelectRole: assigneeSelectRole, //自选id
                            assigneeSelectType: assigneeSelectType, //自选id
                            assigneeBusinessRole: assigneeBusinessRole, //自选id
                            nodeActionVo: nodeActionVo, //办理事项
                            assigneeBusinessRoleId:1,
                        }
                        arr2.push(obj)
                        deep(obj.childNode, arr2)
                    }
                    // 
                    else if (obj.type == 4) {
                        let b = {
                            nodeName: '条件节点',
                            nodeType: 5,
                            processNodeMap: {}
                        };
                        if (obj.conditionNodes) {
                            obj.conditionNodes.forEach((item, index) => {


                                let c = []; // 条件的审核人节点
                                deep(item.childNode, c)

                                if (item.conditionList.length == 0) {
                                    // utils.dialog.alert('请选择条件')
                                    b.processNodeMap[`default`] = [];
                                    b.processNodeMap[`default`] = b.processNodeMap[`default`].concat(c)
                                    // return false
                                } else {
                                    console.log(item.conditionList)
                                    let str = ''
                                    item.conditionList.forEach((v, k) => {

                                        if (v.columnType == 'Double') {
                                            str += `${v.columnId}${that.getRange(v.optType)}${v.zdy1}&&`
                                        } else if (v.columnType == 'four' || v.columnType == 'Three' || v.columnType == 'five') {
                                            str += `${v.zdy2}${that.getRange(v.optType)}${v.zdy1}&&`
                                        }
                                    })
                                    b.processNodeMap[str.substring(0, str.length - 2)] = []
                                    b.processNodeMap[str.substring(0, str.length - 2)] = b.processNodeMap[str.substring(0, str.length - 2)].concat(c)

                                }
                            });
                        }

                        arr2.push(b)
                        deep(obj.childNode, arr2)
                    }
                    // 
                    else if (obj.type == 2) {
                        let assigneeEmpIds = [];
                        let assigneeRoleId = [];
                        let assigneeType;
                        obj.nodeUserList.forEach((item, index) => {
                            if (item.type == 1) {
                                assigneeEmpIds.push(item.targetId)
                                assigneeType = 2
                            } else if (item.type == 2) {
                                assigneeType = 3
                                assigneeRoleId.push(item.targetId)
                            }
                        })
                        // 抄送人
                        obj = {
                            childNode: obj.childNode,
                            nodeName: obj.nodeName,
                            nodeType: 4,
                            assigneeType,
                            assigneeEmpIds: assigneeEmpIds.toString(),
                            assigneeLeaderLevel: null,
                            assigneeRoleId: assigneeRoleId.toString(),
                        }


                        arr2.push(obj)
                        deep(obj.childNode, arr2)

                    }
                }
            }
            deep(obj, a)
            // 
            a.forEach((item, index) => {
                data.nodeDtoList.push(item)
            })
            let finish = {
                nodeName: "结束",
                nodeType: 6,
                assigneeType: null,
                assigneeSpId: null,
                assigneeUserId: null,
                assigneeLeaderLevel: null,
                assigneeRoleId: null
            }
            data.nodeDtoList.push(finish)

            this.goAddProcess(data);
        },
        reErr(data) {
            if (data.childNode) {
                if (data.childNode.type == 1) {
                    //审批人
                    if (data.childNode.error) {
                        this.tipList.push({
                            name: data.childNode.nodeName,
                            type: "审核人",
                        });
                    }
                    this.reErr(data.childNode);
                } else if (data.childNode.type == 2) {
                    if (data.childNode.error) {
                        this.tipList.push({
                            name: data.childNode.nodeName,
                            type: "抄送人",
                        });
                    }
                    this.reErr(data.childNode);
                } else if (data.childNode.type == 5) {
                    if (data.childNode.error) {
                        this.tipList.push({
                            name: data.childNode.nodeName,
                            type: "办理人",
                        });
                    }
                    this.reErr(data.childNode);
                } else if (data.childNode.type == 4) {
                    this.reErr(data.childNode);
                    for (var i = 0; i < data.childNode.conditionNodes.length; i++) {
                        if (data.childNode.conditionNodes[i].error) {
                            this.tipList.push({
                                name: data.childNode.conditionNodes[i].nodeName,
                                type: "条件",
                            });
                        }
                        this.reErr(data.childNode.conditionNodes[i]);
                    }
                }
            } else {
                data.childNode = null;
            }
        },
        // 获取流程结构
        getProcessDetail() {
            let params = {
                processId: this.formData.processId,
            };
            queryProcessDetail(params)
                .then((res) => {
                    if (res.data.processContent) {
                        this.nodeConfig = JSON.parse(res.data.processContent);
                        console.log(this.nodeConfig);
                        this.processConfig = this.defaultData;
                        this.processConfig.nodeconfig = this.nodeconfig;
                        this.flowpermission = this.processConfig.flowpermission;
                        this.directormaxlevel = this.processConfig.directormaxlevel;
                        this.workFlowDef = this.processConfig.workFlowDef
                        this.tableid = this.processConfig.tableid
                        // --------------------------------------------
                        
                    } else {
                        this.processConfig = this.defaultData;
                        this.nodeconfig = this.processConfig.nodeconfig;
                        this.flowpermission = this.processConfig.flowpermission;
                        this.directormaxlevel = this.processConfig.directormaxlevel;
                        this.workFlowDef = this.processConfig.workFlowDef
                        this.tableid = this.processConfig.tableid
                    }
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        // 返回
        goReturn() {
            this.$router.push({ path: "/system/processForm" });
            this.$router.push({ name: "ProcessForm", params: this.formData });
        },
    },
};
</script>
<!-- <style src="@/assets/styles/onePlay/workflow.css"></style> -->
<style lang="scss" scoped>
.processFlow {
    width: 100%;
    padding: 0.15rem;

    .box-content {
        width: 100%;
        padding: 20px;
        border-radius: 8px;
        background: #fff;

        .form-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #d8dae1;
            padding-bottom: 20px;

            .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .return {
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 67px;

                    .txt {
                        font-size: 16px;
                        font-weight: 500;
                        color: #5c5e66;
                        margin-right: 5px;
                    }
                }

                .setting {
                    width: 108px;
                    height: 36px;
                    background: rgba(67, 129, 230, 0.06);
                    border-radius: 4px;
                    border: 1px solid #4381e6;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .img {
                        width: 18px;
                        height: 18px;
                        margin-right: 7px;
                    }

                    .t1 {
                        font-size: 14px;
                        font-weight: 400;
                        color: #4381e6;
                    }
                }

                .right {
                    width: 16px;
                    height: 16px;
                    margin: 0 16px;
                }

                .process {
                    width: 108px;
                    height: 36px;
                    background: #f2f3f5;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .img {
                        width: 18px;
                        height: 18px;
                        margin-right: 7px;
                    }

                    .t2 {
                        font-size: 14px;
                        font-weight: 400;
                        color: #5c5e66;
                    }
                }
            }
        }
    }
}
</style>