<template>
    <div class="processHeader">
        <div class="left">
            <el-link icon="el-icon-d-arrow-left" style="margin-right: 30px;" @click="back">返回</el-link>
            <el-button plain :type="currentTab >= 0 ? 'primary' : ''" >基础设置</el-button>
            <i class="el-icon-arrow-right"></i>
            <el-button plain :type="currentTab >= 1 ? 'primary' : ''" >流程条件</el-button>
            <i class="el-icon-arrow-right"></i>
            <el-button plain :type="currentTab >= 2 ? 'primary' : ''">流程设计</el-button>
        </div>
        <div class="right">
            <el-button type="primary" v-if="currentTab == 3|| currentTab == 1" @click="handleClick(4)">上一步</el-button>
            <el-button type="primary" v-if="currentTab != 3" @click="handleClick(3)">下一步</el-button>
            <el-button type="primary" v-if="currentTab == 3" @click="handleClick(5)">发布流程</el-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        currentTab: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {

        }
    },
    methods: {
        handleClick(index) {
            this.$emit('handleClick', index)
        },
        back() {
            this.$router.push({
                path: '/examine/setting'
            })
            window.localStorage.removeItem('processformData')
        }
    }

}
</script>
<style scoped lang="scss">
.processHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid #ebeef5;
    padding: 0.15rem !important;
    .left {
        display: flex;
        align-items: center;

        i {
            margin: 0 10px;
        }
    }
}
</style>