// 流程相关接口
import request from "@/utils/request";

// 新增流程
export function addProcess(data) {
  return request("post", "/apm/oa/process/addProcess", data);
}
// 删除流程
export function delProcess(data) {
  return request("post", "/apm/oa/process/delProcess", data);
}
// 修改流程
export function updateProcess(data) {
  return request("post", "/apm/oa/process/updateProcess", data);
}
// 查询流程详情
export function queryProcessDetail(data) {
  return request("post", "/apm/oa/process/processInfo", data);
}
// 查询流程列表
export function queryProcessList(data) {
  return request("post", "/apm/oa/process/page", data);
}
// 查询选择分组列表
export function queryProcessGroupTypeList(data) {
  return request("post", "/apm/oa/process/processGroupTypeList", data);
}
// 查询流程类型列表
export function queryProcessTypeList(data) {
    return request("post", "/apm/oa/process/processTypeList", data);
  }
// 通过流程id查询所有的审批项
export function findItems(data) {
  return request("post", "/apm/oa/approveItem/findItems", data);
}
// 更新审批项,批量更新
export function updateItems(data) {
  return request("post", "/apm/oa/approveItem/updateItemTotal", data);
}
// 查询审批人设置成员列表
export function getUserList(data) {
    return request("post", "/apm/oa/process/node/findEmpList", data);
  }
  // 查询审批人设置业务类别
  export function getBusinessTypeList(id,nodeType) {
    return request("get", "/apm/oa/process/node/eventList?processId="+ id+'&nodeType='+nodeType);
  }
  // 启用流程
  export function enableProcess(data) {
    return request("post", "/apm/oa/process/enableProcess", data);
  }
  // 禁用流程
  export function stopProcess(data) {
    return request("post", "/apm/oa/process/stopProcess", data);
  }
  // 业务岗位
  export function getBusinessRoleList(data) {
    return request("post", "/apm/oa/process/node/findAssigneeBusinessRole", data);
  }
  // 删除条件
  export function delCondition(data) {
    return request("post", "/apm/oa/approveItem/delItem", data);
  }
// import Request from "@/utils/request";
// const orderURL = process.env.VUE_APP_ORDER;

// // 查询流程列表 
// export function getProcessList(data) {
//   return Request("post", `${orderURL}/workflow/wfProcess/find`, data);
// }

// // 查询流程分组
// export function getProcessGroup(data) {
//   return Request("post", `${orderURL}/workflow/approveAlarmRelation/findList`, data);
// }

// // 新建流程
export function goAddProcess(data) {
  return request("post", "/apm/oa/process/node/createProcessNode", data);
}

// // 编辑流程
// export function goUpdateProcess(data) {
//   return Request("post", `${orderURL}/workflow/wfProcess/update`, data);
// }

// // 删除流程
// export function goDeleteProcess(data) {
//   return Request("post", `${orderURL}/workflow/wfProcess/del`, data);
// }



// // 获取流程节点
// export function getProcessPoint(data) {
//   return Request("post", `${orderURL}/workflow/wfProcess/findById`, data);
// }

// // 流程节点编辑
// export function goUpdateProcessPoint(data) {
//   return Request("post", `${orderURL}/workflow/wfProcessNode/update`, data);
// }

// 查询角色列表
export function getRoleList(data) {
  return request("post", "/apm/oa/process/node/findRoleList", data);
}

// 查询流程自定义变量
export function getConditionsList(data) {
  return request("post", "/apm/oa/process/node/predefinedVariableList", data);
}

// // 流程上传图片
// export function goUploadWorkFlow(data) {
//   return Request("post", `${orderURL}/file/uploadBase64`, data);
// }