<!--点加号的弹框选择流程-->
<template>
  <div class="add-node-btn-box">
    <div class="add-node-btn">
      <el-popover
      :width="400"
        placement="right-start"
        v-model="visible"
      >
        <div class="add-node-popover-body">
          <a class="add-node-popover-item approver" @click="addType(1)">
            <div class="item-wrapper">
              <span class="iconfont"></span>
            </div>
            <p>审核人</p>
          </a>
          <a class="add-node-popover-item notifier" @click="addType(2)">
            <div class="item-wrapper">
              <span class="iconfont"></span>
            </div>
            <p>抄送人</p>
          </a>
          <a class="add-node-popover-item handler" @click="addType(5)">
            <div class="item-wrapper">
              <span class="iconfont"></span>
            </div>
            <p>办理人</p>
          </a>
          <a class="add-node-popover-item condition" @click="addType(4)">
            <div class="item-wrapper">
              <span class="iconfont"></span>
            </div>
            <p>条件分支</p>
          </a>
        </div>
        <template #reference>
          <button class="btn" type="button">
            <span class="iconfont"></span>
          </button>
        </template>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  props: ["childNodeP"],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    //点击+添加流程
    addType(type) {
      if (type != 4) {
        var data;
        if (type == 1) {
          data = {
            nodeName: "审核人",
            error: true,
            type: 1,
            settype: 1,
            selectMode: 0,
            selectRange: 0,
            directorLevel: 1,
            examineMode: 1,
            noHanderAction: 1,
            examineEndDirectorLevel: 0,
            childNode: this.childNodeP,
            nodeUserList: [],
          };
        } else if (type == 2) {
          data = {
            nodeName: "抄送人",
            error: true,
            type: 2,
            examineMode: 1,
            ccSelfSelectFlag: 1,
            childNode: this.childNodeP,
            nodeUserList: [],
          };
        } else if (type == 5) {
          data = {
            nodeName: "办理人",
            error: true,
            type: 5,
            settype: 1,
            selectMode: 0,
            selectRange: 0,
            directorLevel: 1,
            examineMode: 1,
            noHanderAction: 1,
            examineEndDirectorLevel: 0,
            childNode: this.childNodeP,
            nodeUserList: [],
          };
        }
        this.$emit("updateChildNodeP", data);
      } else {
        this.$emit("updateChildNodeP", {
          nodeName: "路由",
          type: 4,
          childNode: null,
          conditionNodes: [
            {
              nodeName: "条件1",
              error: true,
              type: 3,
              priorityLevel: 1,
              conditionList: [],
              nodeUserList: [],
              childNode: this.childNodeP,
            },
            {
              nodeName: "条件2",
              type: 3,
              priorityLevel: 2,
              conditionList: [],
              nodeUserList: [],
              childNode: null,
            },
          ],
        });
      }
    },
  },
};
</script>
<style src="@/assets/styles/onePlay/workflow.css"></style>
