<template>
  <div>
    <div class="node-wrap" v-if="nodeConfig.type != 4">
      <div class="node-wrap-box" :class="(nodeConfig.type == 0 ? 'start-node ' : '') +
        (isTried && nodeConfig.error ? 'active error' : '')
        ">
        <div>
          <div class="title" :style="'background: rgb(' +
            [
              '87, 106, 149',
              '255, 148, 62',
              '50, 150, 250',
              '59,162,162',
              '59,162,162',
              '59,162,162',
            ][nodeConfig.type] +
            ');'
            ">
            <span class="iconfont" v-show="nodeConfig.type == 1"></span>
            <span class="iconfont" v-show="nodeConfig.type == 2"></span>
            <span class="iconfont" v-show="nodeConfig.type == 5"></span>
            <span v-if="nodeConfig.type == 0">{{ nodeConfig.nodeName }}</span>
            <input type="text" class="ant-input editable-title-input" v-if="nodeConfig.type != 0 && isInput"
              @blur="blurEvent()" @focus="$event.currentTarget.select()" v-focus v-model="nodeConfig.nodeName"
              :placeholder="placeholderList[nodeConfig.type]" />
            <span class="editable-title" @click="clickEvent()" v-if="nodeConfig.type != 0 && !isInput">{{
              nodeConfig.nodeName }}</span>
            <i class="anticon anticon-close close" v-if="nodeConfig.type != 0" @click="delNode()"></i>
          </div>
          <div class="content" @click="setPerson">
            <div class="text" v-if="nodeConfig.type == 0">
              {{
                arrToStr(flowPermission) ? arrToStr(flowPermission) : "所有人"
              }}
            </div>
            <div class="text" v-if="nodeConfig.type == 1">
              <span class="placeholder" v-if="!setApproverStr(nodeConfig)">请选择{{ placeholderList[nodeConfig.type]
                }}</span>
              {{ setApproverStr(nodeConfig) }}
            </div>
            <div class="text" v-if="nodeConfig.type == 2">
              <span class="placeholder" v-if="!setApproverStr(nodeConfig)">请选择{{ placeholderList[nodeConfig.type]
                }}</span>
              {{ setApproverStr(nodeConfig) }}
            </div>
            <div class="text" v-if="nodeConfig.type == 5">
              <span class="placeholder" v-if="!setApproverStr(nodeConfig)">请选择{{ placeholderList[nodeConfig.type - 2]
                }}</span>
              {{ setApproverStr(nodeConfig) }}
            </div>
            <i class="anticon anticon-right arrow"></i>
          </div>
          <div class="error_tip" v-if="isTried && nodeConfig.error">
            <i class="anticon anticon-exclamation-circle" style="color: rgb(242, 86, 67)"></i>
          </div>
        </div>
      </div>
      <addNode :childNodeP="nodeConfig.childNode" @updateChildNodeP="updateChildNodeP2"></addNode>
    </div>
    <div class="branch-wrap" v-if="nodeConfig.type == 4">
      <div class="branch-box-wrap">
        <div class="branch-box">
          <button class="add-branch" @click="addTerm">添加条件</button>
          <div class="col-box" v-for="(item, index) in nodeConfig.conditionNodes" :key="index">
            <div class="condition-node">
              <div class="condition-node-box">
                <div class="auto-judge" :class="isTried && item.error ? 'error active' : ''">
                  <div class="sort-left" v-if="index != 0" @click="arrTransfer(index, -1)">
                    &lt;
                  </div>
                  <div class="title-wrapper">
                    <input type="text" class="ant-input editable-title-input" v-if="isInputList[index]"
                      @blur="blurEvent(index)" @focus="$event.currentTarget.select()" v-focus v-model="item.nodeName" />
                    <span class="editable-title" @click="clickEvent(index)" v-if="!isInputList[index]">{{ item.nodeName
                      }}</span>
                    <span class="priority-title" @click="setPerson(item.priorityLevel)">优先级{{ item.priorityLevel
                      }}</span>
                    <i class="anticon anticon-close close" @click="delTerm(index)"></i>
                  </div>
                  <div class="sort-right" v-if="index != nodeConfig.conditionNodes.length - 1"
                    @click="arrTransfer(index)">
                    &gt;
                  </div>
                  <div class="content" @click="setPerson(item.priorityLevel)">
                    {{ conditionStr(item, index) }}
                  </div>
                  <div class="error_tip" v-if="isTried && item.error">
                    <i class="anticon anticon-exclamation-circle" style="color: rgb(242, 86, 67)"></i>
                  </div>
                </div>
                <addNode :childNodeP="item.childNode" @updateChildNodeP="updateChildNodeP1($event, index)"></addNode>
              </div>
            </div>
            <nodeWrap v-if="item.childNode && item.childNode" :nodeConfig="item.childNode"
              @updateNodeConfig="updateChildNodeP1($event, index)" :tableId="tableId" :isTried="isTried"
              @updateIsTried="isTried = $event" :directorMaxLevel="directorMaxLevel" :conditionsList="conditionsList">
            </nodeWrap>
            <div class="top-left-cover-line" v-if="index == 0"></div>
            <div class="bottom-left-cover-line" v-if="index == 0"></div>
            <div class="top-right-cover-line" v-if="index == nodeConfig.conditionNodes.length - 1"></div>
            <div class="bottom-right-cover-line" v-if="index == nodeConfig.conditionNodes.length - 1"></div>
          </div>
        </div>
        <addNode :childNodeP="nodeConfig.childNode" @updateChildNodeP="updateChildNodeP2"></addNode>
      </div>
    </div>
    <!--审核人-->
    <el-drawer :append-to-body="true" :visible.sync="approverDrawer" direction="rtl" size="650px"
      :before-close="saveApprover">
      <template #title>
        <span style="font-size: 16px"> 审核人设置 </span>
      </template>
      <div class="demo-drawer__content">
        <div class="drawer_content">

          <div class="approver_content">
            <div style="font-weight: bold;margin-bottom: .5em;color: rgba(0, 0, 0, .85);">人员设置</div>
            <el-radio-group v-model="approverConfig.settype" class="clear" @change="changeType($event, 1)">
              <el-radio :label="1">指定成员</el-radio>
              <el-radio :label="2">主管</el-radio>
              <el-radio :label="4">发起人自选</el-radio>
              <el-radio :label="5">发起人自己</el-radio>
              <el-radio :label="3">岗位</el-radio>
              <el-radio :label="6">业务岗位</el-radio>
            </el-radio-group>
            <el-button type="primary" @click="addApprover" v-if="approverConfig.settype == 1">添加/修改成员</el-button>
            <el-button type="primary" @click="addDirectlyRoleApprover"
              v-if="approverConfig.settype == 3">添加/修改岗位</el-button>
            <p class="selected_list" v-if="approverConfig.settype == 1 || approverConfig.settype == 3">
              <span v-for="(item, index) in approverConfig.nodeUserList" :key="index">{{ item.name }}
                <img src="@/assets/images/add-close1.png" @click="
                  removeEle(approverConfig.nodeUserList, item, 'targetId')
                  " />
              </span>
              <a v-if="approverConfig.nodeUserList.length != 0" @click="approverConfig.nodeUserList = []">清除</a>
            </p>
          </div>
          <div class="approver_manager" v-if="approverConfig.settype == 2">
            <p>
              <span>发起人的：</span>
              <select v-model="approverConfig.directorLevel">
                <option v-for="item in directorMaxLevel" :value="item" :key="item">
                  {{ item == 1 ? "直接" : "第" + item + "级" }}主管
                </option>
              </select>
            </p>
            <p class="tip">找不到主管时，由上级主管代审核</p>
          </div>
          <div class="approver_self" v-if="approverConfig.settype == 5">
            <p>该审核节点设置“发起人自己”后，审核人默认为发起人</p>
          </div>
          <div class="approver_self_select" v-show="approverConfig.settype == 4">
            <!-- <el-radio-group
              v-model="approverConfig.selectMode"
              style="width: 100%"
            >
              <el-radio :label="1">选一个人</el-radio>
              <el-radio :label="2">选多个人</el-radio>
            </el-radio-group> -->
            <h3>选择范围</h3>
            <el-radio-group v-model="approverConfig.selectRange" style="width: 100%" @change="changeRange">
              <el-radio :label="1">全公司</el-radio>
              <!-- <el-radio :label="2">指定成员</el-radio> -->
              <el-radio :label="3">指定岗位</el-radio>
            </el-radio-group>
            <el-button type="primary" @click="addApprover" v-if="approverConfig.selectRange == 2">添加/修改成员</el-button>
            <el-button type="primary" @click="addRoleApprover"
              v-if="approverConfig.selectRange == 3">添加/修改岗位</el-button>
            <p class="selected_list" v-if="
              approverConfig.selectRange == 2 ||
              approverConfig.selectRange == 3
            ">
              <span v-for="(item, index) in approverConfig.nodeUserList" :key="index">{{ item.name }}
                <img src="@/assets/images/add-close1.png" @click="
                  removeEle(approverConfig.nodeUserList, item, 'targetId')
                  " />
              </span>
              <a v-if="
                approverConfig.nodeUserList.length != 0 &&
                approverConfig.selectRange != 1
              " @click="approverConfig.nodeUserList = []">清除</a>
            </p>
          </div>
          <!-- <div class="approver_manager" v-if="approverConfig.settype == 7">
            <p>审核终点</p>
            <p style="padding-bottom: 20px">
              <span>发起人的：</span>
              <select v-model="approverConfig.examineEndDirectorLevel">
                <option
                  v-for="item in directorMaxLevel"
                  :value="item"
                  :key="item"
                >
                  {{ item == 1 ? "最高" : "第" + item }}层级主管
                </option>
              </select>
            </p>
          </div> -->
          <div style="padding: 20px;" v-if="approverConfig.settype == 6">
            <el-form ref="topForm" :model="approverConfig" label-width="100px">
              <el-form-item label="业务类型" prop="assigneeSelectType">
                <el-select v-model="approverConfig.assigneeSelectType" placeholder="请选择业务类型">
                  <el-option label="常规审批业务" value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务岗位" prop="assigneeBusinessRole">
                <el-select v-model="approverConfig.assigneeBusinessRole" placeholder="请选择业务岗位"
                  @change="assigneeBusinessRoleChange">
                  <el-option v-for="(ite, index) in assigneeBusinessRoleList" :key="index" :label="ite.roleName"
                    :value="ite.assigneeBusinessRoleId"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="approver_some" v-if="
            (approverConfig.settype == 1 &&
              approverConfig.nodeUserList.length > 1) ||
            approverConfig.settype == 2 ||
            approverConfig.settype == 3 ||
            (approverConfig.settype == 4 && approverConfig.selectMode == 2)
          ">
            <p>多人审核时采用的审核方式</p>
            <el-radio-group v-model="approverConfig.examineMode" class="clear">
              <el-radio :label="1">或签(一名审核人同意即可)</el-radio>
              <br />
              <el-radio :label="2">会签(需所有审核人同意)</el-radio>
            </el-radio-group>
          </div>
          <div class="approver_self_select">
            <h3>审核事项</h3>
            <el-form ref="approverConfigForm" :rules="approverConfigRules" :model="approverConfig" label-width="100px">
              <el-form-item label="业务类别" prop="eventType">
                <el-select v-model="approverConfig.eventType" placeholder="请选择业务类别" clearable>
                  <el-option v-for="item in approveList" :key="item.eventType" :label="item.eventTypeName"
                    :value="item.eventType"></el-option>
                </el-select>
              </el-form-item>
              <template v-if="approverConfig.eventType == 2">
                <el-form-item prop="eventName">
                  <template slot="label">
                    <span style="margin-right: 4px;">审核名称</span>
                    <el-tooltip class="item" effect="dark" content="展示在审批节点处" placement="top">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                  <el-input v-model.trim="approverConfig.eventName" autocomplete="off" maxlength="16"
                    placeholder="请填写审核名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="审核业务" prop="eventAction">
                  <el-select v-model="approverConfig.eventAction" placeholder="请选择审核业务">
                    <el-option v-for="item in approveActionOpt" :key="item.eventAction" :label="item.eventActionName"
                      :value="item.eventAction"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="stageName" v-if="approverConfig.eventAction == 209">
                  <template slot="label">
                    <span style="margin-right: 4px;">审核名称</span>
                    <el-tooltip class="item" effect="dark" content="展示在项目进度中" placement="top">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                  <el-input v-model.trim="approverConfig.stageName" autocomplete="off" maxlength="16"
                    placeholder="请填写阶段名称" clearable></el-input>
                </el-form-item>
                <!-- <el-form-item label="是否支持项目拒绝" prop="eventAction"
                v-if="approverConfig.eventAction == 209">
                <el-switch v-model="approverConfig.rejectEnable" active-value="1" inactive-value="0"></el-switch>
              </el-form-item> -->
              </template>

            </el-form>
          </div>


          <!-- <div
            class="approver_some"
            v-if="approverConfig.settype == 2 || approverConfig.settype == 7"
          >
            <p>审核人为空时</p>
            <el-radio-group
              v-model="approverConfig.noHanderAction"
              class="clear"
            >
              <el-radio :label="1">自动审核通过/不允许发起</el-radio>
              <br />
              <el-radio :label="2">转交给审核管理员</el-radio>
            </el-radio-group>
          </div> -->
        </div>

        <!--审核选择成员-->
        <el-dialog :visible.sync="approverVisible" width="600px" append-to-body @close="approverClose()">
          <template #title>
            <span style="font-size: 16px"> 选择成员 </span>
          </template>
          <div class="person_body clear">
            <div class="person_tree l">
              <el-input placeholder="搜索成员" prefix-icon="el-icon-search" v-model="approverSearchName"
                @input="getDebounceData($event, 1)">
              </el-input>
              <ul>
                <li v-for="(item, index) in departments.employees" :key="index + 'c'" class="check_box">
                  <a :class="toggleClass(approverEmplyessList, item) && 'active'"
                    @click="toChecked(approverEmplyessList, item)" :title="item.departmentNames">
                    <img src="@/assets/images/icon_people.png" />{{
                      item.employeeName
                    }}</a>
                </li>
              </ul>
            </div>
            <div class="has_selected l">
              <p class="clear">
                已选（{{ approverEmplyessList.length }}）
                <a @click="approverEmplyessList = []">清空</a>
              </p>
              <ul>
                <li v-for="(item, index) in approverEmplyessList" :key="index + 'e'">
                  <img src="@/assets/images/icon_people.png" />
                  <span>{{ item.employeeName }}</span>
                  <img src="@/assets/images/cancel.png" @click="removeEle(approverEmplyessList, item)" />
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <el-button @click="approverVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureApprover">确 定</el-button>
          </template>
        </el-dialog>
        <!--审核直接选岗位-->
        <el-dialog title="选择岗位" :visible.sync="directlyRoleVisible" width="600px" append-to-body
          @close="directlyRoleClose()">
          <div class="person_body clear">
            <div class="person_tree l">
              <el-input placeholder="搜索岗位" prefix-icon="el-icon-search" v-model="directlyRoleSearchName"
                @input="getDebounceData($event, 2)">
              </el-input>
              <ul>
                <li v-for="(item, index) in roles" :key="index + 'b'" class="check_box not">
                  <a :title="item.description" :class="toggleClass(roleList, item, 'roleId') && 'active'"
                    @click="roleList = [item]"><img src="@/assets/images/icon_role.png" />{{
                      item.roleName
                    }}</a>
                </li>
              </ul>
            </div>
            <div class="has_selected l">
              <p class="clear">
                已选（{{ roleList.length }}）
                <a @click="roleList = []">清空</a>
              </p>
              <ul>
                <li v-for="(item, index) in roleList" :key="index + 'e'">
                  <img src="@/assets/images/icon_role.png" />
                  <span>{{ item.roleName }}</span>
                  <img src="@/assets/images/cancel.png" @click="removeEle(roleList, item, 'roleId')" />
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="directlyRoleVisible = false">取 消</el-button>
              <el-button type="primary" @click="sureApprover">确 定</el-button>
            </span>
          </template>
        </el-dialog>
        <!--审核发起人自选岗位-->
        <el-dialog title="选择岗位" :visible.sync="approverRoleVisible" width="600px" append-to-body
          @close="approverRoleClose()">
          <div class="person_body clear">
            <div class="person_tree l">
              <el-input placeholder="搜索岗位" prefix-icon="el-icon-search" v-model="approverRoleSearchName"
                @input="getDebounceData($event, 2)">
              </el-input>
              <ul>
                <li v-for="(item, index) in roles" :key="index + 'b'" class="check_box not">
                  <a :title="item.description" :class="toggleClass(roleList, item, 'roleId') && 'active'"
                    @click="roleList = [item]"><img src="@/assets/images/icon_role.png" />{{
                      item.roleName
                    }}</a>
                </li>
              </ul>
            </div>
            <div class="has_selected l">
              <p class="clear">
                已选（{{ roleList.length }}）
                <a @click="roleList = []">清空</a>
              </p>
              <ul>
                <li v-for="(item, index) in roleList" :key="index + 'e'">
                  <img src="@/assets/images/icon_role.png" />
                  <span>{{ item.roleName }}</span>
                  <img src="@/assets/images/cancel.png" @click="removeEle(roleList, item, 'roleId')" />
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="approverRoleVisible = false">取 消</el-button>
              <el-button type="primary" @click="sureApprover">确 定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <div style="position: absolute;bottom: 10px;right: 10px;">
        <el-button type="primary" @click="saveApprover">确 定</el-button>
        <el-button @click="approverDrawer = false">取 消</el-button>
      </div>
    </el-drawer>
    <!--抄送人-->
    <el-drawer :append-to-body="true" :visible.sync="copyerDrawer" direction="rtl" size="650px"
      :before-close="saveCopyer">
      <template #title>
        <span style="font-size: 16px"> 抄送人设置 </span>
      </template>
      <div class="demo-drawer__content">
        <div class="copyer_content drawer_content">
          <div style="font-weight: bold;margin-bottom: .5em;color: rgba(0, 0, 0, .85);">人员设置</div>
          <el-radio-group v-model="copyerConfig.settype" class="clear mb15" @change="changeType($event, 2)" style="">
            <el-radio :label="1">指定成员</el-radio>
            <el-radio :label="3">岗位</el-radio>
            <el-radio :label="6">业务岗位</el-radio>
          </el-radio-group>
          <el-button type="primary" @click="addCopyer" v-if="copyerConfig.settype == 1">添加/修改成员</el-button>
          <el-button type="primary" @click="addCopyerRoleApprover" v-if="copyerConfig.settype == 3">添加/修改岗位</el-button>
          <p class="selected_list" v-if="copyerConfig.settype == 1 || copyerConfig.settype == 3">
            <span v-for="(item, index) in copyerConfig.nodeUserList" :key="index">{{ item.name }}
              <img src="@/assets/images/add-close1.png" @click="
                removeEle(copyerConfig.nodeUserList, item, 'targetId')
                " />
            </span>
            <a v-if="copyerConfig.nodeUserList.length != 0" @click="copyerConfig.nodeUserList = []">清除</a>
          </p>

        </div>

      </div>
      <div style="padding: 20px;" v-if="copyerConfig.settype == 6">
        <el-form ref="topForm" :model="copyerConfig" label-width="100px">
          <el-form-item label="业务类型" prop="assigneeSelectType">
            <el-select v-model="copyerConfig.assigneeSelectType" placeholder="请选择业务类型">
              <el-option label="常规审批业务" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务岗位" prop="assigneeBusinessRole">
            <el-select v-model="copyerConfig.assigneeBusinessRole" placeholder="请选择业务岗位"
              @change="assigneeBusinessRoleChange">
              <el-option v-for="(ite, index) in assigneeBusinessRoleList" :key="index" :label="ite.roleName"
                :value="ite.assigneeBusinessRoleId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="approver_some" v-if="
        (copyerConfig.settype == 1 &&
          copyerConfig.nodeUserList.length > 1) ||
        copyerConfig.settype == 3">
        <p>多人审核时采用的审核方式</p>
        <el-radio-group v-model="copyerConfig.examineMode" class="clear">
          <el-radio :label="1">或签(一名审核人同意即可)</el-radio>
          <br />
          <el-radio :label="2">会签(需所有审核人同意)</el-radio>
        </el-radio-group>
      </div>
      <div style="position: absolute;bottom: 10px;right: 10px;">
        <el-button type="primary" @click="saveCopyer">确 定</el-button>
        <el-button @click="copyerDrawer = false">取 消</el-button>
      </div>
      <!-- 抄送人弹框 -->
      <el-dialog title="选择成员" :visible.sync="copyerVisible" width="600px" append-to-body @close="copyerClose()">
        <div class="person_body clear">
          <div class="person_tree l">
            <el-input placeholder="搜索成员" prefix-icon="el-icon-search" v-model="copyerSearchName"
              @input="getDebounceData($event, 1)">
            </el-input>
            <ul style="height: 360px" v-if="activeName == 1">
              <li v-for="(item, index) in departments.employees" :key="index + 'c'" class="check_box">
                <a :class="toggleClass(copyerEmployessList, item) && 'active'"
                  @click="toChecked(copyerEmployessList, item)" :title="item.departmentNames">
                  <img src="@/assets/images/icon_people.png" />{{
                    item.employeeName
                  }}</a>
              </li>
            </ul>
          </div>
          <div class="has_selected l">
            <p class="clear">
              已选（{{ copyerEmployessList.length + copyerRoleList.length }}）
              <a @click="
                copyerEmployessList = [];
              copyerRoleList = [];
              ">清空</a>
            </p>
            <ul>
              <li v-for="(item, index) in copyerRoleList" :key="index + 'e'">
                <img src="@/assets/images/icon_role.png" />
                <span>{{ item.roleName }}</span>
                <img src="@/assets/images/cancel.png" @click="removeEle(copyerRoleList, item, 'roleId')" />
              </li>
              <li v-for="(item, index) in copyerEmployessList" :key="index + 'e1'">
                <img src="@/assets/images/icon_people.png" />
                <span>{{ item.employeeName }}</span>
                <img src="@/assets/images/cancel.png" @click="removeEle(copyerEmployessList, item)" />
              </li>
            </ul>
          </div>
        </div>
        <template #footer>
          <el-button @click="copyerVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureCopyer">确 定</el-button>
        </template>
      </el-dialog>
      <!--抄送人直接选岗位-->
      <el-dialog title="选择岗位" :visible.sync="copyerRoleVisible" width="600px" append-to-body @close="copyerRoleClose()">
        <div class="person_body clear">
          <div class="person_tree l">
            <el-input placeholder="搜索岗位" prefix-icon="el-icon-search" v-model="directlyRoleSearchName"
              @input="getDebounceData($event, 2)">
            </el-input>
            <ul>
              <li v-for="(item, index) in roles" :key="index + 'b'" class="check_box not">
                <a :title="item.description" :class="toggleClass(copyerRoleList, item, 'roleId') && 'active'"
                  @click="copyerRoleList = [item]"><img src="@/assets/images/icon_role.png" />{{
                    item.roleName
                  }}</a>
              </li>
            </ul>
          </div>
          <div class="has_selected l">
            <p class="clear">
              已选（{{ copyerRoleList.length }}）
              <a @click="copyerRoleList = []">清空</a>
            </p>
            <ul>
              <li v-for="(item, index) in copyerRoleList" :key="index + 'e'">
                <img src="@/assets/images/icon_role.png" />
                <span>{{ item.roleName }}</span>
                <img src="@/assets/images/cancel.png" @click="removeEle(copyerRoleList, item, 'roleId')" />
              </li>
            </ul>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="copyerRoleVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureCopyer">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </el-drawer>
    <!--办理人-->
    <el-drawer :append-to-body="true" :visible.sync="handlerDrawer" direction="rtl" size="650px"
      :before-close="saveHandler">
      <template #title>
        <span style="font-size: 16px"> 办理人设置 </span>
      </template>
      <div class="demo-drawer__content">
        <div class="drawer_content">
          <div class="approver_content">
            <div style="font-weight: bold;margin-bottom: .5em;color: rgba(0, 0, 0, .85);">人员设置</div>
            <el-radio-group v-model="handlerConfig.settype" class="clear" @change="changeHandleType">
              <el-radio :label="1">指定成员</el-radio>
              <el-radio :label="2">主管</el-radio>
              <el-radio :label="3">岗位</el-radio>
              <el-radio :label="4">发起人自选</el-radio>
              <el-radio :label="5">发起人自己</el-radio>
              <el-radio :label="6">业务岗位</el-radio>
            </el-radio-group>
            <el-button type="primary" @click="addHandler" v-if="handlerConfig.settype == 1">添加/修改成员</el-button>
            <el-button type="primary" @click="addHandlerRole" v-if="handlerConfig.settype == 3">添加/修改岗位</el-button>
            <p class="selected_list" v-if="handlerConfig.settype == 1 || handlerConfig.settype == 3">
              <span v-for="(item, index) in handlerConfig.nodeUserList" :key="index">{{ item.name }}
                <img src="@/assets/images/add-close1.png" @click="
                  removeEle(handlerConfig.nodeUserList, item, 'targetId')
                  " />
              </span>
              <a v-if="handlerConfig.nodeUserList.length != 0" @click="handlerConfig.nodeUserList = []">清除</a>
            </p>
          </div>
          <div class="approver_manager" v-if="handlerConfig.settype == 2">
            <p>
              <span>发起人的：</span>
              <select v-model="handlerConfig.directorLevel">
                <option v-for="item in directorMaxLevel" :value="item" :key="item">
                  {{ item == 1 ? "直接" : "第" + item + "级" }}主管
                </option>
              </select>
            </p>
            <p class="tip">找不到主管时，由上级主管代审核</p>
          </div>
          <div class="approver_self" v-if="handlerConfig.settype == 5">
            <p>该审核节点设置“发起人自己”后，办理人默认为发起人</p>
          </div>
          <div class="approver_self_select" v-show="handlerConfig.settype == 4">
            <h3>选择范围</h3>
            <el-radio-group v-model="handlerConfig.selectRange" style="width: 100%" @change="changeHandleRange">
              <el-radio :label="1">全公司</el-radio>
              <el-radio :label="3">指定岗位</el-radio>
            </el-radio-group>
            <el-button type="primary" @click="addHandlerRoleBySelf"
              v-if="handlerConfig.selectRange == 3">添加/修改岗位</el-button>
            <p class="selected_list" v-if="
              handlerConfig.selectRange == 2 || handlerConfig.selectRange == 3
            ">
              <span v-for="(item, index) in handlerConfig.nodeUserList" :key="index">{{ item.name }}
                <img src="@/assets/images/add-close1.png" @click="
                  removeEle(handlerConfig.nodeUserList, item, 'targetId')
                  " />
              </span>
              <a v-if="
                handlerConfig.nodeUserList.length != 0 &&
                handlerConfig.selectRange != 1
              " @click="handlerConfig.nodeUserList = []">清除</a>
            </p>
          </div>
          <div style="padding: 20px;" v-if="handlerConfig.settype == 6">
            <el-form ref="topForm" :model="handlerConfig" label-width="100px">
              <el-form-item label="业务类型" prop="assigneeSelectType">
                <el-select v-model="handlerConfig.assigneeSelectType" placeholder="请选择业务类型">
                  <el-option label="常规审批业务" value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务岗位" prop="assigneeBusinessRole">
                <el-select v-model="handlerConfig.assigneeBusinessRole" placeholder="请选择业务岗位"
                  @change="assigneeBusinessRoleChange">
                  <el-option v-for="(ite, index) in assigneeBusinessRoleList" :key="index" :label="ite.roleName"
                    :value="ite.assigneeBusinessRoleId"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="approver_some" v-if="
            (handlerConfig.settype == 1 &&
              handlerConfig.nodeUserList.length > 1) ||
            handlerConfig.settype == 2 ||
            handlerConfig.settype == 3 ||
            (handlerConfig.settype == 4 && handlerConfig.selectMode == 2)
          ">
            <p>多人审核时采用的审核方式</p>
            <el-radio-group v-model="handlerConfig.examineMode" class="clear">
              <el-radio :label="1">或签(一名办理人同意即可)</el-radio>
              <br />
              <el-radio :label="2">会签(须所有办理人同意)</el-radio>
            </el-radio-group>
          </div>
          <div class="approver_self_select">
            <h3>办理事项</h3>
            <el-form ref="dealForm" :rules="dealRules" :model="handlerConfig" label-width="100px">
              <el-form-item label="业务类别" prop="eventType">
                <el-select v-model="handlerConfig.eventType" placeholder="请选择业务类别">
                  <el-option v-for="item in eventList" :key="item.eventType" :label="item.eventTypeName"
                    :value="item.eventType"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="办理名称" prop="eventName">
                <el-input v-model.trim="handlerConfig.eventName" autocomplete="off" maxlength="16" placeholder="请填写办理名称"
                  clearable></el-input>
              </el-form-item>
              <el-form-item label="办理业务" prop="eventAction">
                <el-select v-model="handlerConfig.eventAction" placeholder="请选择办理业务">
                  <el-option v-for="item in eventActionOpt" :key="item.eventAction" :label="item.eventActionName"
                    :value="item.eventAction"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="是否支持项目拒绝"  prop="eventType" v-if="handlerConfig.eventType == 1">
                <el-switch v-model="handlerConfig.rejectEnable" active-value="1" inactive-value="0"></el-switch>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <!--办理选择成员-->
        <el-dialog title="选择成员" :visible.sync="handlerVisible" width="600px" append-to-body @close="handlerClose()">
          <div class="person_body clear">
            <div class="person_tree l">
              <el-input placeholder="搜索成员" prefix-icon="el-icon-search" v-model="handleSearchName"
                @input="getDebounceData($event, 1)">
              </el-input>
              <ul>
                <li v-for="(item, index) in departments.employees" :key="index + 'c'" class="check_box">
                  <a :class="toggleClass(handlerEmployessList, item) && 'active'"
                    @click="toChecked(handlerEmployessList, item)" :title="item.departmentNames">
                    <img src="@/assets/images/icon_people.png" />{{
                      item.employeeName
                    }}</a>
                </li>
              </ul>
            </div>
            <div class="has_selected l">
              <p class="clear">
                已选（{{ handlerEmployessList.length }}）
                <a @click="handlerEmployessList = []">清空</a>
              </p>
              <ul>
                <li v-for="(item, index) in handlerEmployessList" :key="index + 'e'">
                  <img src="@/assets/images/icon_people.png" />
                  <span>{{ item.employeeName }}</span>
                  <img src="@/assets/images/cancel.png" @click="removeEle(handlerEmployessList, item)" />
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <el-button @click="handlerVisible = false">取 消</el-button>
            <el-button type="primary" @click="sureHandler">确 定</el-button>
          </template>
        </el-dialog>
        <!--办理直接选岗位-->
        <el-dialog title="选择岗位" :visible.sync="directlyHandlerRoleVisible" width="600px" append-to-body
          @close="directlyHandlerRoleClose()">
          <div class="person_body clear">
            <div class="person_tree l">
              <el-input placeholder="搜索岗位" prefix-icon="el-icon-search" v-model="directlyHandlerRoleSearchName"
                @input="getDebounceData($event, 2)">
              </el-input>
              <ul>
                <li v-for="(item, index) in roles" :key="index + 'b'" class="check_box not">
                  <a :title="item.description" :class="toggleClass(roleList, item, 'roleId') && 'active'"
                    @click="roleList = [item]"><img src="@/assets/images/icon_role.png" />{{
                      item.roleName
                    }}</a>
                </li>
              </ul>
            </div>
            <div class="has_selected l">
              <p class="clear">
                已选（{{ roleList.length }}）
                <a @click="roleList = []">清空</a>
              </p>
              <ul>
                <li v-for="(item, index) in roleList" :key="index + 'e'">
                  <img src="@/assets/images/icon_role.png" />
                  <span>{{ item.roleName }}</span>
                  <img src="@/assets/images/cancel.png" @click="removeEle(roleList, item, 'roleId')" />
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="directlyHandlerRoleVisible = false">取 消</el-button>
              <el-button type="primary" @click="sureHandler">确 定</el-button>
            </span>
          </template>
        </el-dialog>
        <!--办理发起人自选岗位-->
        <el-dialog title="选择岗位" :visible.sync="handlerRoleVisible" width="600px" append-to-body
          @close="handlerRoleClose()">
          <div class="person_body clear">
            <div class="person_tree l">
              <el-input placeholder="搜索岗位" prefix-icon="el-icon-search" v-model="handlerRoleSearchName"
                @input="getDebounceData($event, 2)">
              </el-input>
              <ul>
                <li v-for="(item, index) in roles" :key="index + 'b'" class="check_box not">
                  <a :title="item.description" :class="toggleClass(roleList, item, 'roleId') && 'active'"
                    @click="roleList = [item]"><img src="@/assets/images/icon_role.png" />{{
                      item.roleName
                    }}</a>
                </li>
              </ul>
            </div>
            <div class="has_selected l">
              <p class="clear">
                已选（{{ roleList.length }}）
                <a @click="roleList = []">清空</a>
              </p>
              <ul>
                <li v-for="(item, index) in roleList" :key="index + 'e'">
                  <img src="@/assets/images/icon_role.png" />
                  <span>{{ item.roleName }}</span>
                  <img src="@/assets/images/cancel.png" @click="removeEle(roleList, item, 'roleId')" />
                </li>
              </ul>
            </div>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="handlerRoleVisible = false">取 消</el-button>
              <el-button type="primary" @click="sureHandler">确 定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <div style="position: absolute;bottom: 10px;right: 10px;">
        <el-button type="primary" @click="saveHandler">确 定</el-button>
        <el-button @click="handlerDrawer = false">取 消</el-button>
      </div>
    </el-drawer>
    <!--条件-->
    <el-drawer :append-to-body="true" :visible.sync="conditionDrawer" direction="rtl" size="650px"
      :before-close="saveCondition">
      <template #title>
        <span style="font-size: 16px"> 条件设置 </span>
      </template>
      <div class="demo-drawer__content">
        <div class="condition_content drawer_content">
          <p class="tip">当审核单同时满足以下条件时进入此流程</p>
          <ul>
            <li v-for="(item, index) in conditionConfig.conditionList" :key="index">
              <el-tooltip class="item" effect="light" :content="item.showName" placement="bottom">ype == 1 ? '发起人' : item.showName" placement="bottom">
                <span class="ellipsis">{{ item.type == 1 ? "发起人" : item.showName }}：</span>
              </el-tooltip>
              <div v-if="item.type == 1">
                <p :class="conditionConfig.nodeUserList.length > 0
                  ? 'selected_list'
                  : ''
                  " @click.self="addConditionRole" style="cursor: text">
                  <span v-for="(item1, index1) in conditionConfig.nodeUserList" :key="index1">
                    {{ item1.name
                    }}<img src="@/assets/images/add-close1.png" @click="
                      removeEle(
                        conditionConfig.nodeUserList,
                        item1,
                        'targetId'
                      )
                      " />
                  </span>
                  <input type="text" placeholder="请选择具体人员/岗位/部门" v-if="conditionConfig.nodeUserList.length == 0"
                    @click="addConditionRole" />
                </p>
              </div>
              <div v-else-if="item.columnType == 'four'" style="margin-bottom: 10px;">
                <div style="display: flex;align-items: center;">
                  <select disabled v-model="item.optType" style="position: static;background: #eee;"
                    :style="'width:' + (item.optType == 6 ? 370 : 100) + 'px'" @change="changeOptType(item)">
                    <option value="1">小于</option>
                    <option value="2">大于</option>
                    <option value="3">小于等于</option>
                    <option value="4">等于</option>
                    <option value="5">大于等于</option>
                    <option value="6">介于两个数之间</option>
                  </select>
                  <el-select v-model="item.zdy1" filterable :placeholder="'请选择' + item.showName" class="gardenSelect">
                    <el-option v-for="(v, k) in item.fixedDownBoxValue" :key="k" :label="v" :value="v">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div v-else>
                <p>
                  <select v-model="item.optType" :style="'width:' + (item.optType == 6 ? 370 : 100) + 'px'"
                    @change="changeOptType(item)">
                    <option value="1">小于</option>
                    <option value="2">大于</option>
                    <option value="3">小于等于</option>
                    <option value="4">等于</option>
                    <option value="5">大于等于</option>
                    <option value="6">介于两个数之间</option>
                  </select>
                  <input v-if="item.optType != 6" type="text" :placeholder="'请输入' + item.showName" v-enter-number="2333"
                    v-model="item.zdy1" style="padding: 0 15px;" />
                </p>
                <p v-if="item.optType == 6">
                  <input type="text" style="width: 75px" class="mr_10" v-enter-number="222" v-model="item.zdy1" />
                  <select style="width: 60px" v-model="item.opt1">
                    <option value="<">&lt;</option>
                    <option value="≤">≤</option>
                  </select>
                  <span class="ellipsis" style="
                      display: inline-block;
                      width: 60px;
                      vertical-align: text-bottom;
                    ">{{ item.showName }}</span>
                  <select style="width: 60px" class="ml_10" v-model="item.opt2">
                    <option value="<">&lt;</option>
                    <option value="≤">≤</option>
                  </select>
                  <input type="text" style="width: 75px" v-enter-number="2" v-model="item.zdy2" />
                </p>
              </div>
              <a v-if="[1, 2, 21].includes(item.type)" @click="
                conditionConfig.nodeUserList = [];
              removeEle(conditionConfig.conditionList, item, 'columnId');
              ">删除</a>

            </li>
          </ul>
          <el-button type="primary" @click="addCondition">添加条件</el-button>
          <el-dialog title="选择条件" :visible.sync="conditionVisible" width="480px" append-to-body
            @close="closeConditionDialog()">
            <p style="margin-bottom: 10px">请选择用来区分审核流程的条件字段</p>
            <p class="check_box">
              <template v-if="conditions.length > 0">
                <a style="line-height: 16px;margin-bottom: 13px;display: flex;user-select: none;"
                  v-for="(item, index) in conditions" :key="index"
                  :class="toggleClass(conditionList, item, 'columnId') && 'active'"
                  @click="toChecked(conditionList, item, 'columnId')">{{ item.showName }}</a>
              </template>
              <template v-else>
                <p>暂无条件</p>
              </template>
            </p>
            <template #footer>
              <el-button @click="conditionVisible = false">取 消</el-button>
              <el-button type="primary" @click="sureCondition">确 定</el-button>
            </template>
          </el-dialog>
        </div>
      </div>
      <div style="position: absolute;bottom: 10px;right: 10px;">
        <el-button type="primary" @click="saveCondition">确 定</el-button>
        <el-button @click="conditionDrawer = false">取 消</el-button>
      </div>
    </el-drawer>
    <nodeWrap v-if="nodeConfig.childNode && nodeConfig.childNode" :nodeConfig="nodeConfig.childNode"
      @updateNodeConfig="updateChildNodeP2" :tableId="tableId" :isTried="isTried" :directorMaxLevel="directorMaxLevel"
      :conditionsList="conditionsList"></nodeWrap>
  </div>
</template>
<script>
import addNode from "@/components/addNode.vue";
import nodeWrap from "@/components/nodeWrap.vue";
import { getRoleList, getUserList, getBusinessTypeList, getBusinessRoleList } from "@/api/examine/process.js";
export default {
  name: "nodeWrap",
  components: {
    addNode, nodeWrap
  },
  props: [
    "nodeConfig",
    "flowPermission",
    "directorMaxLevel",
    "isTried",
    "tableId",
    "conditionsList",
  ],
  computed: {
    eventActionOpt() {
      if (this.handlerConfig.eventType) {
        let arr = this.eventList.filter(i => i.eventType == this.handlerConfig.eventType)[0].nodeActionVoList;
        return arr
      } else {
        return []
      }
    },
    approveActionOpt() {
      if (this.approverConfig.eventType) {
        let arr = this.approveList.filter(i => i.eventType == this.approverConfig.eventType)[0].nodeActionVoList;
        return arr
      } else {
        return []
      }
    }
  },
  data() {
    return {
      placeholderList: ["发起人", "审核人", "抄送人", "办理人"],
      approverConfigRules: {
        eventName: [
          { required: true, message: "请填写审核名称", trigger: "blur" },
        ],
        eventAction: [
          { required: true, message: "请选择审核业务", trigger: "change" },
        ],
        stageName: [
          { required: true, message: "请填写阶段名称", trigger: "blur" },
        ],
      },
      dealRules: {
        eventName: [
          { required: true, message: "请填写办理名称", trigger: "blur" },
        ],
        eventAction: [
          { required: true, message: "请选择办理业务", trigger: "change" },
        ],

      },
      isInputList: [],
      isInput: false,
      promoterVisible: false,
      promoterDrawer: false,
      departments: {
        employees: [],
      },
      checkedDepartmentList: [],
      checkedEmployessList: [],
      promoterSearchName: "",
      flowPermission1: this.flowPermission,
      approverDrawer: false,
      approverVisible: false,
      approverRoleVisible: false,
      directlyRoleVisible: false,
      copyerRoleVisible: false,
      handlerDrawer: false,
      handlerVisible: false,
      handleSearchName: "",
      directlyHandlerRoleVisible: false,
      directlyHandlerRoleSearchName: "",
      handlerRoleVisible: false,
      handlerRoleSearchName: "",

      handlerConfig: {},
      handlerEmployessList: [],
      handlerRoleList: [],

      approverConfig: {},
      approverEmplyessList: [],
      approverSearchName: "",
      directlyRoleSearchName: "",
      roles: [],
      roleList: [],
      approverRoleSearchName: "",
      copyerDrawer: false,
      copyerVisible: false,
      copyerConfig: {},
      copyerSearchName: "",
      activeName: "1",
      copyerEmployessList: [],
      copyerRoleList: [],
      ccSelfSelectFlag: [],
      conditionDrawer: false,
      conditionVisible: false,
      conditionConfig: {},
      conditionsConfig: {
        conditionNodes: [],
      },
      bPriorityLevel: "",
      conditions: this.conditionsList,
      conditionList: [],
      conditionRoleVisible: false,
      conditionRoleSearchName: "",
      conditionDepartmentList: [],
      conditionEmployessList: [],
      conditionRoleList: [],
      approveList: [],
      eventList: [],
      assigneeBusinessRoleList: [],
    };
  },
  mounted() {
    this.getBusinessTypeList(2);
    this.getBusinessTypeList(3);
    this.getBusinessRoleList()

  },
  methods: {
    assigneeBusinessRoleChange(e) {
      this.approverConfig.assigneeBusinessRoleObj = this.assigneeBusinessRoleList.find(item => item.assigneeBusinessRoleId == e)
    },
    updateChildNodeP2(e) {
      this.nodeConfig.childNode = e
    },
    updateChildNodeP1(e, i) {
      this.nodeConfig.conditionNodes[i].childNode = e
    },
    getBusinessRoleList() {
      getBusinessRoleList().then((res) => {
        if (res.isSuccess == 'yes') {
          this.assigneeBusinessRoleList = res.data
          // 避免列表数据没有加载处理就校验数据
          if (this.nodeConfig.type == 1) {
            this.nodeConfig.error = !this.setApproverStr(this.nodeConfig);
          } else if (this.nodeConfig.type == 2) {
            this.nodeConfig.error = !this.setApproverStr(this.nodeConfig);
          } else if (this.nodeConfig.type == 5) {
            this.nodeConfig.error = !this.setApproverStr(this.nodeConfig);
          } else {
            for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
              this.nodeConfig.conditionNodes[i].error =
                this.conditionStr(this.nodeConfig.conditionNodes[i], i) ==
                "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1;
            }
          }
        }
      })
    },
    getBusinessTypeList(nodeType) {
      getBusinessTypeList(this.$route.query.processId, nodeType).then((res) => {
        if (res.isSuccess == 'yes') {
          if (nodeType == 2) {
            this.approveList = res.data;
          } else {
            this.eventList = res.data;
          }

        }
      })
    },
    clickEvent(index) {
      if (index || index === 0) {
        this.$set(this.isInputList, index, true);
      } else {
        this.isInput = true;
      }
    },
    blurEvent(index) {
      if (index || index === 0) {
        this.$set(this.isInputList, index, false);
        this.nodeConfig.conditionNodes[index].nodeName = this.nodeConfig
          .conditionNodes[index].nodeName
          ? this.nodeConfig.conditionNodes[index].nodeName
          : "条件";
      } else {
        this.isInput = false;
        this.nodeConfig.nodeName = this.nodeConfig.nodeName
          ? this.nodeConfig.nodeName
          : this.placeholderList[this.nodeConfig.type];
      }
    },
    conditionStr(item, index) {
      var { conditionList, nodeUserList } = item;
      if (conditionList.length == 0) {
        return index == this.nodeConfig.conditionNodes.length - 1 &&
          this.nodeConfig.conditionNodes[0].conditionList.length != 0
          ? "其他条件进入此流程"
          : "请设置条件";
      } else {
        let str = "";
        for (var i = 0; i < conditionList.length; i++) {
          var {
            columnId,
            columnType,
            showType,
            showName,
            optType,
            zdy1,
            opt1,
            zdy2,
            opt2,
            fixedDownBoxValue,
          } = conditionList[i];
          if (columnId == 0) {
            if (nodeUserList.length != 0) {
              str += "发起人属于：";
              str +=
                nodeUserList
                  .map((item) => {
                    return item.name;
                  })
                  .join("或") + " 并且 ";
            }
          }
          if (columnType == "four") {
            if (optType != 6 && zdy1) {
              var optTypeStr = ["", "<", ">", "≤", "=", "≥"][optType]
              str += `${showName} ${optTypeStr} ${zdy1} 并且 `
            } else if (optType == 6 && zdy1 && zdy2) {
              str += `${zdy1} ${opt1} ${showName} ${opt2} ${zdy2} 并且 `
            }
          }
          if (columnType == "Double") {
            if (optType != 6 && zdy1) {
              var optTypeStr = ["", "<", ">", "≤", "=", "≥"][optType];
              str += `${showName} ${optTypeStr} ${zdy1} 并且 `;
            } else if (optType == 6 && zdy1 && zdy2) {
              str += `${zdy1} ${opt1} ${showName} ${opt2} ${zdy2} 并且 `;
            }
          }
        }
        return str ? str.substring(0, str.length - 4) : "请设置条件";
      }
    },
    dealStr(str, obj) {
      let arr = [];
      let list = str.split(",");
      for (var elem in obj) {
        list.map((item) => {
          if (item == elem) {
            arr.push(obj[elem].value);
          }
        });
      }
      return arr.join("或");
    },
    addConditionRole() {
      this.conditionRoleSearchName = "";
      this.conditionRoleVisible = true;
      this.activeName = "1";
      this.conditionDepartmentList = [];
      this.conditionEmployessList = [];
      this.conditionRoleList = [];
      for (var i = 0; i < this.conditionConfig.nodeUserList.length; i++) {
        var { type, name, targetId } = this.conditionConfig.nodeUserList[i];
        if (type == 1) {
          this.conditionEmployessList.push({
            employeeName: name,
            id: targetId,
          });
        } else if (type == 2) {
          this.conditionRoleList.push({
            roleName: name,
            roleId: targetId,
          });
        } else if (type == 3) {
          this.conditionDepartmentList.push({
            departmentName: name,
            id: targetId,
          });
        }
      }
    },
    sureConditionRole() {
      this.conditionConfig.nodeUserList = [];
      this.conditionRoleList.map((item) => {
        this.conditionConfig.nodeUserList.push({
          type: 2,
          targetId: item.roleId,
          name: item.roleName,
        });
      });
      this.conditionDepartmentList.map((item) => {
        this.conditionConfig.nodeUserList.push({
          type: 3,
          targetId: item.id,
          name: item.departmentName,
        });
      });
      this.conditionEmployessList.map((item) => {
        this.conditionConfig.nodeUserList.push({
          type: 1,
          targetId: item.id,
          name: item.employeeName,
        });
      });
      this.conditionRoleVisible = false;
    },
    //添加条件
    addCondition() {
      this.conditionList = [];
      this.conditionVisible = true;
      if (this.conditionConfig.conditionList) {
        for (var i = 0; i < this.conditionConfig.conditionList.length; i++) {
          var { columnId } = this.conditionConfig.conditionList[i];
          if (columnId == 0) {
            this.conditionList.push({ columnId: 0 });
          } else {
            this.conditionList.push(
              this.conditions.filter((item) => {
                return item.columnId == columnId;
              })[0]
            );
          }
        }
      }
    },
    changeOptType(item) {
      if (item.optType == 1) {
        item.zdy1 = 2;
      } else {
        item.zdy1 = 1;
        item.zdy2 = 2;
      }
    },
    //确定条件
    sureCondition() {
      //1.弹窗有，外面无+
      //2.弹窗有，外面有不变
      console.log(this.conditionList);
      for (var i = 0; i < this.conditionList.length; i++) {
        var {
          columnId,
          zdy2,
          showName,
          columnName,
          showType,
          columnType,
          fixedDownBoxValue,
        } = this.conditionList[i];
        if (
          this.toggleClass(
            this.conditionConfig.conditionList,
            this.conditionList[i],
            "columnId"
          )
        ) {
          continue;
        }
        if (columnId == 0) {
          this.conditionConfig.nodeUserList == [];
          this.conditionConfig.conditionList.push({
            type: 1,
            columnId: columnId,
            showName: "发起人",
          });
        } else {
          if (columnType == "Double") {
            this.conditionConfig.conditionList.push({
              showType: showType,
              columnId: columnId,
              type: 2,
              showName: showName,
              optType: "1",
              zdy1: "",
              opt1: "<",
              zdy2: "",
              opt2: "<",
              columnDbname: columnName,
              columnType: columnType,
            });
          } else if (columnType == "four") {
            this.conditionConfig.conditionList.push({
              showType: showType,
              columnId: columnId,
              optType: "4",
              type: 21,
              showName: showName,
              zdy1: "",
              opt1: "<",
              zdy2:zdy2,
              columnDbname: columnName,
              columnType: columnType,
              fixedDownBoxValue: fixedDownBoxValue,
            });
          }
        }
      }
      ////3.弹窗无，外面有-
      for (let i = this.conditionConfig.conditionList.length - 1; i >= 0; i--) {
        if (
          !this.toggleClass(
            this.conditionList,
            this.conditionConfig.conditionList[i],
            "columnId"
          )
        ) {
          this.conditionConfig.conditionList.splice(i, 1);
        }
      }
      this.conditionConfig.conditionList.sort(function (a, b) {
        return a.columnId - b.columnId;
      });
      this.conditionVisible = false;
    },
    //保存条件
    saveCondition() {
      this.conditionDrawer = false;
      var a = this.conditionsConfig.conditionNodes.splice(
        this.bPriorityLevel - 1,
        1
      ); //截取旧下标
      this.conditionsConfig.conditionNodes.splice(
        this.conditionConfig.priorityLevel - 1,
        0,
        a[0]
      ); //填充新下标
      this.conditionsConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1;
      });
      for (var i = 0; i < this.conditionsConfig.conditionNodes.length; i++) {
        this.conditionsConfig.conditionNodes[i].error =
          this.conditionStr(this.conditionsConfig.conditionNodes[i], i) ==
          "请设置条件" &&
          i != this.conditionsConfig.conditionNodes.length - 1;
      }
      this.$emit("updateNodeConfig", this.conditionsConfig);
    },
    //搜索成员/岗位
    getDebounceData: _.debounce(function (event, type) {
      if (type === 1) {
        this.getUserList(event);
      } else {
        this.getRoleList(event);
      }
    }, 500),

    // handleClick() {
    //   this.copyerSearchName = "";
    //   this.conditionRoleSearchName = "";
    //   if (this.activeName == 1) {
    //     this.getDepartmentList();
    //   } else {
    //     this.getRoleList();
    //   }
    // },
    //添加抄送人
    addCopyer() {
      this.getUserList();
      this.copyerSearchName = "";
      this.copyerVisible = true;
      this.activeName = "1";
      this.copyerEmployessList = [];
      this.copyerRoleList = [];
      for (var i = 0; i < this.copyerConfig.nodeUserList.length; i++) {
        var { type, name, targetId } = this.copyerConfig.nodeUserList[i];
        if (type == 1) {
          this.copyerEmployessList.push({
            employeeName: name,
            id: targetId,
          });
        } else if (type == 2) {
          this.copyerRoleList.push({
            roleName: name,
            roleId: targetId,
          });
        }
      }
    },
    //抄送人选择岗位
    addCopyerRoleApprover() {
      this.getRoleList();
      this.copyerRoleVisible = true;
      this.directlyRoleSearchName = "";
      this.roleList = [];
      for (var i = 0; i < this.approverConfig.nodeUserList.length; i++) {
        var { name, targetId } = this.approverConfig.nodeUserList[i];
        this.roleList.push({
          roleName: name,
          roleId: targetId,
        });
      }
    },
    //确定抄送人
    sureCopyer() {
      console.log(this.copyerConfig);
      this.copyerConfig.nodeUserList = [];
      if (
        this.copyerConfig.settype == 1 ||
        (this.copyerConfig.settype == 4 &&
          this.copyerConfig.selectRange == 2)
      ) {
        this.copyerEmployessList.map((item) => {
          this.copyerConfig.nodeUserList.push({
            type: 1,
            targetId: item.id,
            name: item.employeeName,
          });
        });
        this.copyerVisible = false;
      } else if (
        this.copyerConfig.settype == 4 &&
        this.copyerConfig.selectRange == 3
      ) {
        this.copyerRoleList.map((item) => {
          this.copyerConfig.nodeUserList.push({
            type: 2,
            targetId: item.roleId,
            name: item.roleName,
          });
        });
        this.copyerRoleVisible = false;
      } else if (this.copyerConfig.settype == 3) {
        this.copyerRoleList.map((item) => {
          this.copyerConfig.nodeUserList.push({
            type: 2,
            targetId: item.roleId,
            name: item.roleName,
          });
        });
        this.copyerRoleVisible = false;
      }
    },
    //保存抄送人
    saveCopyer() {
      this.copyerConfig.ccSelfSelectFlag =
        this.ccSelfSelectFlag.length == 0 ? 0 : 1;
      this.copyerConfig.error = !this.setApproverStr(this.copyerConfig);
      if (this.copyerConfig.settype == 3 && this.copyerConfig.nodeUserList.length == 0) {
        this.$message.error('请选择岗位');
        return
      }
      this.$emit("updateNodeConfig", this.copyerConfig);
      this.copyerDrawer = false;
    },
    //添加办理人
    addHandler() {
      this.getUserList();
      this.handlerVisible = true;
      this.handleSearchName = "";
      this.handlerEmployessList = [];
      for (var i = 0; i < this.handlerConfig.nodeUserList.length; i++) {
        var { name, targetId } = this.handlerConfig.nodeUserList[i];
        this.handlerEmployessList.push({
          employeeName: name,
          id: targetId,
        });
      }
    },
    //确定办理人
    sureHandler() {
      this.handlerConfig.nodeUserList = [];
      if (
        this.handlerConfig.settype == 1 ||
        (this.handlerConfig.settype == 4 && this.handlerConfig.selectRange == 2)
      ) {
        this.handlerEmployessList.map((item) => {
          this.handlerConfig.nodeUserList.push({
            type: 1,
            targetId: item.id,
            name: item.employeeName,
          });
        });
        this.handlerVisible = false;
      } else if (
        this.handlerConfig.settype == 4 &&
        this.handlerConfig.selectRange == 3
      ) {
        this.roleList.map((item) => {
          this.handlerConfig.nodeUserList.push({
            type: 2,
            targetId: item.roleId,
            name: item.roleName,
          });
        });
        this.handlerRoleVisible = false;
      } else if (this.handlerConfig.settype == 3) {
        this.roleList.map((item) => {
          this.handlerConfig.nodeUserList.push({
            type: 2,
            targetId: item.roleId,
            name: item.roleName,
          });
        });
        this.directlyHandlerRoleVisible = false;
      }
    },
    //添加办理人岗位
    addHandlerRole() {
      this.getRoleList();
      this.directlyHandlerRoleVisible = true;
      this.directlyHandlerRoleSearchName = "";
      this.roleList = [];
      for (var i = 0; i < this.handlerConfig.nodeUserList.length; i++) {
        var { name, targetId } = this.handlerConfig.nodeUserList[i];
        this.roleList.push({
          roleName: name,
          roleId: targetId,
        });
      }
    },
    //添加指定办理人岗位
    addHandlerRoleBySelf() {
      this.getRoleList();
      this.handlerRoleVisible = true;
      this.handlerRoleSearchName = "";
      this.roleList = [];
      for (var i = 0; i < this.handlerConfig.nodeUserList.length; i++) {
        var { name, targetId } = this.handlerConfig.nodeUserList[i];
        this.roleList.push({
          roleName: name,
          roleId: targetId,
        });
      }
    },
    //保存办理人
    saveHandler() {
      this.handlerConfig.error = !this.setApproverStr(this.handlerConfig);
      this.$refs.dealForm.validate((valid) => {
        if (valid) {
          if (this.handlerConfig.settype == 3 && this.handlerConfig.nodeUserList.length == 0) {
            this.$message.error('请选择岗位');
            return
          }
          this.$emit("updateNodeConfig", this.handlerConfig);
          this.handlerDrawer = false;
        }
      });

    },
    changeRange() {
      this.approverConfig.nodeUserList = [];
    },
    changeHandleRange() {
      this.handlerConfig.nodeUserList = [];
    },
    changeType(val, type) {
      if (type == 1) {
        // 审核人
        this.approverConfig.nodeUserList = [];
        this.approverConfig.examineMode = 1;
        this.approverConfig.noHanderAction = 2;
        if (val == 2) {
          this.approverConfig.directorLevel = 1;
        } else if (val == 4) {
          this.approverConfig.selectMode = 1;
          this.approverConfig.selectRange = 1;
        } else if (val == 3) {
          this.approverConfig.examineEndDirectorLevel = 1;
        }
      } else {
        this.copyerConfig.nodeUserList = [];
        this.copyerConfig.examineMode = 1;
        this.copyerConfig.noHanderAction = 2;
        if (val == 2) {
          this.copyerConfig.directorLevel = 1;
        } else if (val == 4) {
          this.copyerConfig.selectMode = 1;
          this.copyerConfig.selectRange = 1;
        } else if (val == 3) {
          this.copyerConfig.examineEndDirectorLevel = 1;
        }
      }

    },
    changeHandleType(val) {
      this.handlerConfig.nodeUserList = [];
      this.handlerConfig.examineMode = 1;
      this.handlerConfig.noHanderAction = 2;
      if (val == 2) {
        this.handlerConfig.directorLevel = 1;
      } else if (val == 4) {
        this.handlerConfig.selectMode = 1;
        this.handlerConfig.selectRange = 1;
      } else if (val == 3) {
        this.handlerConfig.examineEndDirectorLevel = 1;
      }
    },
    //添加审核人
    addApprover() {
      this.getUserList();
      this.approverVisible = true;
      this.approverSearchName = "";
      this.approverEmplyessList = [];
      for (var i = 0; i < this.approverConfig.nodeUserList.length; i++) {
        var { name, targetId } = this.approverConfig.nodeUserList[i];
        this.approverEmplyessList.push({
          employeeName: name,
          id: targetId,
        });
      }
    },
    //审核人选择岗位
    addDirectlyRoleApprover() {
      this.getRoleList();
      this.directlyRoleVisible = true;
      this.directlyRoleSearchName = "";
      this.roleList = [];
      for (var i = 0; i < this.approverConfig.nodeUserList.length; i++) {
        var { name, targetId } = this.approverConfig.nodeUserList[i];
        this.roleList.push({
          roleName: name,
          roleId: targetId,
        });
      }
    },
    //添加自选中的岗位
    addRoleApprover() {
      this.getRoleList();
      this.approverRoleVisible = true;
      this.approverRoleSearchName = "";
      this.roleList = [];
      for (var i = 0; i < this.approverConfig.nodeUserList.length; i++) {
        var { name, targetId } = this.approverConfig.nodeUserList[i];
        this.roleList.push({
          roleName: name,
          roleId: targetId,
        });
      }
    },
    //确定审核人
    sureApprover() {
      this.approverConfig.nodeUserList = [];
      if (
        this.approverConfig.settype == 1 ||
        (this.approverConfig.settype == 4 &&
          this.approverConfig.selectRange == 2)
      ) {
        this.approverEmplyessList.map((item) => {
          this.approverConfig.nodeUserList.push({
            type: 1,
            targetId: item.id,
            name: item.employeeName,
          });
        });
        this.approverVisible = false;
      } else if (
        this.approverConfig.settype == 4 &&
        this.approverConfig.selectRange == 3
      ) {
        this.roleList.map((item) => {
          this.approverConfig.nodeUserList.push({
            type: 2,
            targetId: item.roleId,
            name: item.roleName,
          });
        });
        this.approverRoleVisible = false;
      } else if (this.approverConfig.settype == 3) {
        this.roleList.map((item) => {
          this.approverConfig.nodeUserList.push({
            type: 2,
            targetId: item.roleId,
            name: item.roleName,
          });
        });
        this.directlyRoleVisible = false;
      }
    },
    setApproverStr(nodeConfig) {
      if (nodeConfig.settype == 1) {
        if (nodeConfig.nodeUserList.length == 1) {
          return nodeConfig.nodeUserList[0].name;
        } else if (nodeConfig.nodeUserList.length > 1) {
          if (nodeConfig.examineMode == 1) {
            return this.arrToStr(nodeConfig.nodeUserList);
          } else if (nodeConfig.examineMode == 2) {
            return nodeConfig.nodeUserList.length + "人会签";
          }
        }
      } else if (nodeConfig.settype == 2) {
        let level =
          nodeConfig.directorLevel == 1
            ? "直接主管"
            : "第" + nodeConfig.directorLevel + "级主管";
        if (nodeConfig.examineMode == 1) {
          return level;
        } else if (nodeConfig.examineMode == 2) {
          return level + "会签";
        }
      } else if (nodeConfig.settype == 4) {
        if (nodeConfig.selectRange == 1) {
          return "发起人自选";
        } else {
          if (nodeConfig.nodeUserList.length > 0) {
            if (nodeConfig.selectRange == 2) {
              return "发起人自选";
            } else {
              return "发起人从" + nodeConfig.nodeUserList[0].name + "中自选";
            }
          } else {
            return "";
          }
        }
      } else if (nodeConfig.settype == 5) {
        return "发起人自己";
      } else if (nodeConfig.settype == 3) {//岗位
        if (nodeConfig.nodeUserList.length == 1) {
          return nodeConfig.nodeUserList[0].name + ['或签', '会签'][nodeConfig.examineMode - 1]
        }
      } else if (nodeConfig.settype == 6) {//业务岗位
        let obj = this.assigneeBusinessRoleList.find(item => item.assigneeBusinessRoleId == nodeConfig.assigneeBusinessRole)
        return obj ? obj.roleName : '';
      }
    },
    //保存审核人
    saveApprover() {
      this.approverConfig.error = !this.setApproverStr(this.approverConfig);
      this.$refs.approverConfigForm.validate((valid) => {
        if (valid) {
          if (this.approverConfig.settype == 3 && this.approverConfig.nodeUserList.length == 0) {
            this.$message.error('请选择岗位');
            return;
          }
          this.$emit("updateNodeConfig", this.approverConfig);
          this.approverDrawer = false;
        } else {
          return false;
        }
      });

    },
    //添加发起人--弃用
    addPromoter() {
      this.promoterVisible = true;
      this.promoterSearchName = "";
      this.checkedEmployessList = [];
      this.checkedDepartmentList = [];
      for (var i = 0; i < this.flowPermission1.length; i++) {
        var { type, name, targetId } = this.flowPermission1[i];
        if (type == 1) {
          this.checkedEmployessList.push({
            employeeName: name,
            id: targetId,
          });
        } else if (type == 3) {
          this.checkedDepartmentList.push({
            departmentName: name,
            id: targetId,
          });
        }
      }
    },
    //确定发起人--弃用
    surePromoter() {
      this.flowPermission1 = [];
      this.checkedDepartmentList.map((item) => {
        this.flowPermission1.push({
          type: 3,
          targetId: item.id,
          name: item.departmentName,
        });
      });
      this.checkedEmployessList.map((item) => {
        this.flowPermission1.push({
          type: 1,
          targetId: item.id,
          name: item.employeeName,
        });
      });
      this.promoterVisible = false;
    },
    //保存发起人--弃用
    savePromoter() {
      this.$emit("update:flowPermission", this.flowPermission1);
      this.promoterDrawer = false;
    },
    arrToStr(arr) {
      if (arr) {
        return arr
          .map((item) => {
            return item.name;
          })
          .toString();
      }
    },
    toggleStrClass(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : [];
      return a.some((item) => {
        return item == key;
      });
    },
    toStrChecked(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : [];
      var isIncludes = this.toggleStrClass(item, key);
      if (!isIncludes) {
        a.push(key);
        item.zdy1 = a.toString();
      } else {
        this.removeStrEle(item, key);
      }
    },
    removeStrEle(item, key) {
      let a = item.zdy1 ? item.zdy1.split(",") : [];
      var includesIndex;
      a.map((item, index) => {
        if (item == key) {
          includesIndex = index;
        }
      });
      a.splice(includesIndex, 1);
      item.zdy1 = a.toString();
    },
    toggleClass(arr, elem, key = "id") {
      return arr.some((item) => {
        return item[key] == elem[key];
      });
    },
    toChecked(arr, elem, key = "id") {
      var isIncludes = this.toggleClass(arr, elem, key);
      !isIncludes ? arr.push(elem) : this.removeEle(arr, elem, key);
    },
    removeEle(arr, elem, key = "id") {
      var includesIndex;
      arr.map((item, index) => {
        if (item[key] == elem[key]) {
          includesIndex = index;
        }
      });
      arr.splice(includesIndex, 1);
    },
    //获取岗位列表
    getRoleList(val) {
      let params = {
        roleName: val,
      };
      getRoleList(params)
        .then((res) => {
          this.roles = [];
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              let obj = {
                code: "",
                scope: "1",
                description: "",
                status: "",
                roleId: item.roleId,
                roleName: item.roleName,
              };
              this.roles.push(obj);
            });
          } else {
            this.roles = [];
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取员工
    getUserList(val) {
      let params = {
        kw: val,
      };
      getUserList(params)
        .then((res) => {
          let employees = [];
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              let obj = {
                id: item.candidateEmpId,
                employeeName: item.candidateUserName,
                isLeave: "0",
                open: "false",
              };
              employees.push(obj);
            });
          } else {
            employees = [];
          }
          this.departments.employees = employees;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    delNode() {
      this.$emit("updateNodeConfig", this.nodeConfig.childNode);
    },
    addTerm() {
      let len = this.nodeConfig.conditionNodes.length + 1;
      this.nodeConfig.conditionNodes.push({
        nodeName: "条件" + len,
        type: 3,
        priorityLevel: len,
        conditionList: [],
        nodeUserList: [],
        childNode: null,
      });
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error =
          this.conditionStr(this.nodeConfig.conditionNodes[i], i) ==
          "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1;
      }
      this.$emit("updateNodeConfig", this.nodeConfig);
    },
    delTerm(index) {
      this.nodeConfig.conditionNodes.splice(index, 1);
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error =
          this.conditionStr(this.nodeConfig.conditionNodes[i], i) ==
          "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1;
      }
      this.$emit("updateNodeConfig", this.nodeConfig);
      if (this.nodeConfig.conditionNodes.length == 1) {
        if (this.nodeConfig.childNode) {
          if (this.nodeConfig.conditionNodes[0].childNode) {
            this.reData(
              this.nodeConfig.conditionNodes[0].childNode,
              this.nodeConfig.childNode
            );
          } else {
            this.nodeConfig.conditionNodes[0].childNode =
              this.nodeConfig.childNode;
          }
        }
        this.$emit(
          "updateNodeConfig",
          this.nodeConfig.conditionNodes[0].childNode
        );
      }
    },
    reData(data, addData) {
      if (!data.childNode) {
        data.childNode = addData;
      } else {
        this.reData(data.childNode, addData);
      }
    },
    setPerson(priorityLevel) {
      var { type } = this.nodeConfig;
      if (type == 0) {
        this.promoterDrawer = false;
        this.flowPermission1 = this.flowPermission;
      } else if (type == 1) {
        this.approverDrawer = true;
        this.approverConfig = JSON.parse(JSON.stringify(this.nodeConfig));
        this.approverConfig.settype = this.approverConfig.settype
          ? this.approverConfig.settype
          : 1;
      } else if (type == 2) {
        this.copyerDrawer = true;
        this.copyerConfig = JSON.parse(JSON.stringify(this.nodeConfig));
        this.ccSelfSelectFlag =
          this.copyerConfig.ccSelfSelectFlag == 0
            ? []
            : [this.copyerConfig.ccSelfSelectFlag];
      } else if (type == 5) {
        this.handlerDrawer = true;
        this.handlerConfig = JSON.parse(JSON.stringify(this.nodeConfig));
        this.handlerConfig.settype = this.handlerConfig.settype
          ? this.handlerConfig.settype
          : 1;
      } else {
        this.conditionDrawer = true;
        this.bPriorityLevel = priorityLevel;
        this.conditionsConfig = JSON.parse(JSON.stringify(this.nodeConfig));
        this.conditionConfig = this.conditionsConfig.conditionNodes[priorityLevel - 1];
        console.log(this.conditionConfig);
      }
    },
    arrTransfer(index, type = 1) {
      //向左-1,向右1
      this.nodeConfig.conditionNodes[index] =
        this.nodeConfig.conditionNodes.splice(
          index + type,
          1,
          this.nodeConfig.conditionNodes[index]
        )[0];
      this.nodeConfig.conditionNodes.map((item, index) => {
        item.priorityLevel = index + 1;
      });
      for (var i = 0; i < this.nodeConfig.conditionNodes.length; i++) {
        this.nodeConfig.conditionNodes[i].error =
          this.conditionStr(this.nodeConfig.conditionNodes[i], i) ==
          "请设置条件" && i != this.nodeConfig.conditionNodes.length - 1;
      }
      this.$emit("updateNodeConfig", this.nodeConfig);
    },
    //发起人选择关闭
    originatorClose() {
      this.promoterVisible = false;
    },
    //审核选择关闭
    approverClose() {
      this.approverVisible = false;
    },
    //审核人直接选择岗位
    directlyRoleClose() {
      this.roles = [];
      this.directlyRoleVisible = false;
    },
    //审核指定岗位
    approverRoleClose() {
      this.roles = [];
      this.approverRoleVisible = false;
    },
    //抄送人选择关闭
    copyerClose() {
      this.copyerVisible = false;
    },
    //抄送人直接选择岗位
    copyerRoleClose() {
      this.roles = [];
      this.copyerRoleVisible = false;
    },
    //条件选择关闭
    conditionsClose() {
      this.conditionRoleVisible = false;
    },
    //办理人关闭
    handlerClose() {
      this.handlerVisible = false;
    },
    //办理人直接选岗位关闭
    directlyHandlerRoleClose() {
      this.roles = [];
      this.directlyHandlerRoleVisible = false;
    },
    //办理人指定岗位关闭
    handlerRoleClose() {
      this.roles = [];
      this.handlerRoleVisible = false;
    },
    //条件关闭
    closeConditionDialog() {
      this.conditionVisible = false;
    },
  },
};
</script>
<style src="@/assets/styles/onePlay/workflow.css"></style>
<style lang="scss" scoped>
.ellipsis {
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}
.mr_10{
  margin-right: 10px;
}
.demo-drawer__footer {
  width: 550px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 20px;
  padding-right: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.el-dialog__header {
  background: red;
}

.el-drawer__header {
  align-items: center;
  color: #72767b;
  display: flex;
}

.el-radio-group {
  display: flex;


}

.error_tip {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(150%, 0px);
  font-size: 24px;
}

.add-node-popover-body {
  display: flex;
}

.promoter_content {
  padding: 0 20px;
}

.condition_content .el-button,
.copyer_content .el-button,
.approver_self_select .el-button,
.promoter_content .el-button,
.approver_content .el-button {
  margin-bottom: 20px;
}

.promoter_content p {
  padding: 18px 0;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.promoter_person .el-dialog__body {
  padding: 10px 20px 14px 20px;
}

.person_body {
  border: 1px solid #f5f5f5;
  height: 500px;
  display: flex;
}

.person_tree {
  padding: 10px 12px 0 8px;
  width: 280px;
  height: 100%;
  border-right: 1px solid #f5f5f5;
}

.person_tree input {
  padding-left: 22px;
  width: 210px;
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #d5dadf;
  background: url(~@/assets/images/list_search.png) no-repeat 10px center;
  background-size: 14px 14px;
  margin-bottom: 14px;
}

.tree_nav span {
  display: inline-block;
  padding-right: 10px;
  margin-right: 5px;
  max-width: 6em;
  color: #38adff;
  font-size: 12px;
  cursor: pointer;
  background: url(~@/assets/images/jiaojiao.png) no-repeat right center;
}

.tree_nav span:last-of-type {
  background: none;
}

.person_tree ul,
.has_selected ul {
  height: 420px;
  overflow-y: auto;
}

.person_tree li {
  padding: 5px 0;
}

.person_tree li i {
  float: right;
  padding-left: 24px;
  padding-right: 10px;
  color: #3195f8;
  font-size: 12px;
  cursor: pointer;
  background: url(~@/assets/images/next_level_active.png) no-repeat 10px center;
  border-left: 1px solid rgb(238, 238, 238);
}

.person_tree li a.active+i {
  color: rgb(197, 197, 197);
  background-image: url(~@/assets/images/next_level.png);
  pointer-events: none;
}

.person_tree img {
  width: 14px;
  vertical-align: middle;
  margin-right: 5px;
}

.has_selected {
  width: 276px;
  height: 100%;
  font-size: 12px;
}

.has_selected ul {
  height: 460px;
}

.has_selected p {
  padding-left: 19px;
  padding-right: 20px;
  line-height: 37px;
  border-bottom: 1px solid #f2f2f2;
}

.has_selected p a {
  float: right;
}

.has_selected ul li {
  margin: 11px 26px 13px 19px;
  line-height: 17px;
}

.has_selected li span {
  vertical-align: middle;
}

.has_selected li img:first-of-type {
  width: 14px;
  vertical-align: middle;
  margin-right: 5px;
}

.has_selected li img:last-of-type {
  float: right;
  margin-top: 2px;
  width: 14px;
}

el-radio-group {
  padding: 20px 0;
}

.approver_content {
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}

.approver_content .el-radio,
.approver_some .el-radio,
.approver_self_select .el-radio {
  margin-bottom: 20px;
}

.copyer_content .el-checkbox {
  margin-bottom: 20px;
}

.el-checkbox__label {
  font-size: 12px;
}

.condition_content,
.copyer_content,
.approver_self_select,
.approver_manager,
.approver_content,
.approver_some {
  padding: 20px 20px 0;
}

.approver_manager p:first-of-type,
.approver_some p {
  line-height: 19px;
  font-size: 14px;
  margin-bottom: 14px;
}

.approver_manager p {
  line-height: 32px;
}

.approver_manager select {
  width: 420px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
}

.approver_manager p.tip {
  margin: 10px 0 22px 0;
  font-size: 12px;
  line-height: 16px;
  color: #f8642d;
}

.approver_self {
  padding: 28px 20px;
}

.selected_list {
  margin-bottom: 20px;
  line-height: 30px;
}

.selected_list span {
  margin-right: 10px;
  padding: 3px 6px 3px 9px;
  line-height: 12px;
  white-space: nowrap;
  border-radius: 2px;
  border: 1px solid rgba(220, 220, 220, 1);
}

.selected_list img {
  margin-left: 5px;
  width: 7px;
  height: 7px;
  cursor: pointer;
}

.approver_self_select h3 {
  margin: 5px 0 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
}

.condition_copyer .el-drawer__body .priority_level {
  position: absolute;
  top: 11px;
  right: 30px;
  width: 100px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
}

.condition_content p.tip {
  margin: 20px 0;
  width: 510px;
  text-indent: 17px;
  line-height: 45px;
  background: rgba(241, 249, 255, 1);
  border: 1px solid rgba(64, 163, 247, 1);
  color: #46a6fe;
  font-size: 14px;
}

.condition_content ul {
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.condition_content li>span {
  float: left;
  margin-right: 8px;
  width: 70px;
  line-height: 32px;
  text-align: right;
}

.condition_content li>div {
  display: inline-block;
  width: 370px;
}

.condition_content li:not(:last-child)>div>p {
  margin-bottom: 10px;
}

.condition_content li>div>p:not(:last-child) {
  margin-bottom: 10px;
}

.condition_content li>a {
  float: right;
  margin-right: 10px;
  margin-top: 7px;
}

.condition_content li select,
.condition_content li input {
  width: 100%;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
}

.condition_content li select+input {
  width: 260px;
}

.condition_content li select {
  margin-right: 10px;
  width: 100px;
}

.condition_content li p.selected_list {
  padding-left: 10px;
  border-radius: 4px;
  min-height: 32px;
  border: 1px solid rgba(217, 217, 217, 1);
}

.condition_content li p.check_box {
  line-height: 32px;
}

.condition_list .el-dialog__body {
  padding: 16px 26px;
}

.condition_list p {
  color: #666666;
  margin-bottom: 10px;
}

.condition_list p.check_box {
  margin-bottom: 0;
  line-height: 36px;
}

.radio_box a,
.check_box a {
  font-size: 12px;
  position: relative;
  padding-left: 20px;
  margin-right: 30px;
  cursor: pointer;
  color: #333;
  white-space: pre;
}

.radio_box a::before,
.check_box a::before {
  position: absolute;
  width: 14px;
  height: 14px;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  left: 0;
  top: 1px;
  content: "";
}

:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.check_box a.active::after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAqUlEQVQY033PoUrDUQCF8d/VobhyQbkPsGdYGZhUGIhlQQxG4dalJYN7kLuyJmi2aV/2DcQwuBiuwSRo+QuGsVPPB+c7wZbEUvtY4ra3BdrDI85xGGKpA9zgruX000G7uMcl3nDcwwOGOMAslhqw6KA1zlpO7yGWOsIz+pjjCFN84KTl9AqhmzrFE/Y7xU+MW06rP+cdaDm94Arf+MLkP7Tp6XUs9WJT9ws1kC07ActjIgAAAABJRU5ErkJggg==) no-repeat center;
}

.check-dot.active::after,
.radio_box a.active::after,
.check_box a.active::after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  content: "";
}

.condition_content li>span {
  width: 96px;
}

.event-item {
  margin: 10px 0;
  display: flex;

  span {
    margin-right: 10px;

  }
}
</style>
<style>
.el-drawer__header {
  padding-bottom: 20px;
  margin: 0;
  background: #f9f9f9;
}

.el-drawer__body {
  background: #fff;
}

.el-drawer__footer {
  background: #fff;
}
</style>